import React from 'react'
import PaymentArchive from './PaymentArchive'
import { useSelector } from 'react-redux'
import Preloader from '../Preloader/Preloader'
import NoData from '../NoData/NoData'


const PaymentArchiveContainer = ({ archive }) => {

  const preloader = useSelector((state) => state.paymentArchivePage.preloader)

  if (preloader) return <Preloader />
  if (!archive || !archive.length) return <NoData />

  let sortServicesArchive = {}
  // let sortMonthArchive = {}
  let years = []

  // archive.sort((a, b) => a.date_sum > b.date_sum ? 1 : -1)  // Сортируем по дате по убыванию
  // archive.sort((a, b) => a.date_sum > b.date_sum ? 1 : ( a.date_sum === b.date_sum) ? ((a.service_type > b.service_type) ? 1 : (a.service_type === b.service_type) ? ((a.service_id > b.service_id) ? 1 : -1) : -1 ) : -1 )

  // Перебирая архив заполняем years годами из архива и заполняем sortServicesArchive тем-же архивом, но сортированным по сервисам
  archive.forEach((item) => {
    if (!years.includes(item.date_sum.substr(0, 4))) years.push(item.date_sum.substr(0, 4))
    let service = [item.service_type, item.service_id]
    if (sortServicesArchive[service]) {
      sortServicesArchive[service].push(item)
    } else {
      sortServicesArchive[service] = [item]
    }

    // let dateMonth = item.date_sum
    // if (sortMonthArchive[dateMonth]) {
    //   sortMonthArchive[dateMonth].push(item)
    // } else {
    //   sortMonthArchive[dateMonth] = [item]
    // }

  })

  sortServicesArchive = Object.values(sortServicesArchive)

  years.sort((a, b) => b - a)  // Сортируем список годов по убыванию

  return (<PaymentArchive archive={archive} sortServicesArchive={sortServicesArchive} years={years} />)
}

export default PaymentArchiveContainer