export const AUTHORIZATION = 'AUTHORIZATION'
export const PRELOADER = 'PRELOADER'
export const SUCCESS_MSG = 'SUCCESS_MSG'
export const FAILURE_MSG = 'FAILURE_MSG'
export const GET_NOTICES = 'GET_NOTICES'
export const PRELOADER_NOTICES = 'PRELOADER_NOTICES'
export const DEL_NOTICES = 'DEL_NOTICES'
export const SUCCESS_RESET_MSG = 'SUCCESS_RESET_MSG'
export const FAILURE_RESET_MSG = 'FAILURE_RESET_MSG'
export const RESET_REDUX = 'RESET_REDUX'
export const SET_LOG_EMAIL = 'SET_LOG_EMAIL'
export const GET_BANK_DETAILS = 'GET_BANK_DETAILS'
