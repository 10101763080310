import React, { useState } from 'react'
import Contract from './Contract'
import { useSelector, useDispatch } from 'react-redux'
import { getArchiveTC } from '../../../store/actionCreators/paymentArchiveActionCreator'
import { delContractTC } from '../../../store/actionCreators/contractsActionCreator'
import { getWaterMetersTC } from '../../../store/actionCreators/waterMetersActionCreator'
import { getIndicationArchiveTC } from '../../../store/actionCreators/indicationArchiveActionCreator'
import ModalVerification from './ModalVerification/ModalVerification'

const ContractContainer = ({ contractId, invoice, branch, fio }) => {

  const userToken = useSelector((state) => state.userPage.userToken)
  const waterMeters = useSelector((state) => state.waterMetersPage.waterMeters)
  const archive = useSelector((state) => state.paymentArchivePage.paymentArchive.[contractId])
  const indicArchive = useSelector((state) => state.indicationArchivePage.indicationArchive.[contractId])
  const dispatch = useDispatch()

  const theseWaterMeters = waterMeters.filter((wm) => {
    return String(wm.contract_id) === String(contractId)
  })

  const [namePage, setNamePage] = useState("invoice") //"sendNumber", "invoice", "paymentArchive", "generalMeter", "branch"


  //======================================== Management burger menu ============================
  const [isMenuOpen, toggleMenu] = useState(false)
  const toggleMenuMode = (val) => {
    toggleMenu(val);
  }
  //====================================== / Management burger menu =============================

  const delContractHandler = () => {

    const payloadToRemove = {
      apiData: {
        action: 'delete',
        contract: contractId
      },
      userToken
    }

    dispatch(delContractTC(payloadToRemove))
    setIsOpen(false)
  }

  const namePageHandler = (newNamePage, contractId) => {
    toggleMenuMode(false)
    setNamePage(newNamePage)

    //Если находим в стейте нужный инвойс, то ничего не далаем. 
    // Инвойс уже в стейте, запрос к серверу делать не нужно
    // если не находим, - то диспатчим санку - делаем запрос на сервер
    if (newNamePage === 'sendNumber') {
      if (!waterMeters.length) dispatch(getWaterMetersTC(userToken))
    }

    if (newNamePage === 'paymentArchive' && !archive) {
      dispatch(getArchiveTC({ contractId, userToken }))
    }

    if (newNamePage === 'indicationArchive' && !indicArchive) {
      dispatch(getIndicationArchiveTC({ contractId, userToken }))
    }

  }

  //=======================================  Modal Window ==========================
  const [modalIsOpen, setIsOpen] = useState(false)

  const openModal = () => {
    toggleMenuMode(false)
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }
  //======================================= / Modal Window ==========================

  return (
    <>
      <Contract
        invoice={invoice}
        theseWaterMeters={theseWaterMeters}
        contractId={contractId}
        fio={fio}
        namePage={namePage}
        namePageHandler={namePageHandler}
        archive={archive}
        indicArchive={indicArchive}
        branch={branch}
        openModal={openModal}
        isMenuOpen={isMenuOpen}
        toggleMenuMode={toggleMenuMode}
      />
      <ModalVerification
        delContractHandler={delContractHandler}
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        operation='delContract'
      />
    </>
  )
}

export default ContractContainer
