import React from 'react'
import logo from '../../images/logo82.png'
import s from './Header.module.scss'

const Header = ({ userEmail, exitHandler }) => {
  return (
    <div className={userEmail ? s.headerIn : s.header}>
      <div className={s.logo}>
        <a href="http://teploseti.zp.ua">
          <div className={s.image}>
            <img src={logo} alt="logo" />
          </div>
          <div className={s.text}>
            КОНЦЕРН
            <div>
              МІСЬКІ ТЕПЛОВІ МЕРЕЖІ
            </div>
          </div>
        </a>
      </div>
      {userEmail
        ?
        <div className={s.userName}>
          <div>{userEmail}</div>
          <div className={s.exit} onClick={exitHandler}>Вихід</div>
        </div>
        : null}
    </div>
  )
}

export default Header
