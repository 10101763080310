import React, { useEffect } from 'react'
import MainPage from './MainPage'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { getInvoicesTC } from '../../store/actionCreators/invoicesActionCreator'
import { getContractsTC, openingAddContractFormAC, errorAddContractMsgAC } from '../../store/actionCreators/contractsActionCreator'
import { getNoticesTC, delNoticesAC, getBankDetailsTC } from '../../store/actionCreators/userActionCreator'
import Preloader from './Contract/Preloader/Preloader'
import { limitContractsMsg } from '../../constants'

const MainPageContainer = () => {
  const contracts = useSelector((state) => state.contractsPage.contracts)
  const preloader = useSelector((state) => state.contractsPage.preloader)
  const invoices = useSelector((state) => state.invoicesPage.invoices)
  const isOpenAddContractForm = useSelector((state) => state.contractsPage.isOpenAddContractForm)
  const errorAddContractMsg = useSelector((state) => state.contractsPage.errorAddContractMsg)
  const userEmail = useSelector((state) => state.userPage.userEmail)
  const userToken = useSelector((state) => state.userPage.userToken)
  const dispatch = useDispatch()

  const setAddModeHandler = (val) => {
    if (contracts.length >= 5) dispatch(errorAddContractMsgAC(limitContractsMsg))
    else {
      // console.log("сработал")
      dispatch(openingAddContractFormAC(val))
    }
  }

  useEffect(() => {

    if (userEmail) {
      dispatch(delNoticesAC())
      dispatch(getContractsTC(userToken))
      dispatch(getInvoicesTC(userToken))
      dispatch(getNoticesTC(userToken))
      dispatch(getBankDetailsTC(userToken))
    }

  }, [userToken])

  if (!userEmail) return <Redirect to={'/login'} />

  if (preloader) return <Preloader />

  return (
    <MainPage
      contracts={contracts}
      invoices={invoices}
      userEmail={userEmail}
      setAddModeHandler={setAddModeHandler}
      // preloader={preloader}
      errorAddContractMsg={errorAddContractMsg}
      isOpenAddContractForm={isOpenAddContractForm}
    />
  )
}

export default MainPageContainer
