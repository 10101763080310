import React from "react"
import s from "./OneYear.module.scss"

const OneYearArchive = ({ oneYear }) => {

  const data = oneYear.map((pay, index) => {

    const dt = new Date(pay.date_sum)
    const mounthString = dt.toLocaleString('uk', { month: 'long' });
    return (
      <tr key={index}>
        <td className={s.tdMonth} >{mounthString}</td>
        <td>{pay.sum_saldo_pred}</td>
        <td>{pay.sum_nach}</td>
        <td>{pay.sum_op} </td>
        <td>{pay.sum_saldo}</td>
      </tr>
    )
  })

  return (
    <table className={s.oneYearTable} >
      <thead>
        <tr>
          <th>Звітний місяць</th>
          <th>На поча&shy;ток</th>
          <th>Нарахо&shy;ва&shy;но</th>
          <th>Спла&shy;че&shy;но</th>
          <th>Сума до сплати</th>
        </tr>
      </thead>
      <tbody>
        {data}
      </tbody>
    </table>
  )
}

export default OneYearArchive