import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import spinner from './../../images/spinner.svg'
import { authorizationTC, getFailureMsgAC, getSuccessMsgAC, setLogEmailAC } from '../../store/actionCreators/userActionCreator'
import ModalWindow from '../ModalWindow/ModalWindow'
import s from './LogReg.module.scss'
import LogReg from './LogReg'
import { activationLetter } from '../../constants'

const LogRegContainer = () => {
  const dispatch = useDispatch()
  const userEmail = useSelector((state) => state.userPage.userEmail)
  const preloader = useSelector((state) => state.userPage.preloader)
  const failureMsg = useSelector((state) => state.userPage.failureMsg)
  const successMsg = useSelector((state) => state.userPage.successMsg)
  const logEmail = useSelector((state) => state.userPage.logEmail)


  //Определяет внешний вид компонента - залогинивание или регистрация
  const [regMode, setRegMode] = useState(false)

  const ModeHandler = () => {
    dispatch(getFailureMsgAC(''))
    setRegMode(!regMode)
  }

  //Удаляем пробелы введенные пользователем
  const pressKeyHandler = (value) => value.replace(/\s/g, '')

  const onSubmit = (formData) => {
    dispatch(getSuccessMsgAC(''))
    dispatch(getFailureMsgAC(''))
    const regData = {
      email: formData.email,
      password: formData.password,
      profile: {
        phone: formData.phone,
      },
    }
    dispatch(authorizationTC(regData))
  }

  // useEffect(() => {
  //   dispatch(passwdResetAC(''))
  // })

  // Если изменилось сообщение об успешной регистрации, то перейти в режим залогинивания
  useEffect(() => {
    setRegMode(false)
  }, [successMsg])


  // Определяет - скрывать пароль или нет
  const [iconVisibility, setIconVisibility] = useState(false)
  const displayIcon = () => {
    setIconVisibility(!iconVisibility)
  }


  //======================================== Modal Window ============================
  const [modalIsOpen, setIsOpen] = useState(false)

  const closeModal = () => {
    dispatch(getSuccessMsgAC(''))
    dispatch(getFailureMsgAC(''))
    dispatch(setLogEmailAC(''))
    setIsOpen(false)
  }

  let msg

  if (successMsg) {
    msg = activationLetter(successMsg)
    // `На електрону пошту ${successMsg} було відправлено листа для підтвердження.
    // Якщо Ви не бачите листа, перевірте папку "Спам".
    // Після підтвердження ви отримаєте можливість увійти в особистий кабінет`
  }

  if (failureMsg) {
    msg = failureMsg
  }
  //======================================= / Modal Window ==========================



  useEffect(() => {
    if (msg) setIsOpen(true)
  }, [msg])

  if (userEmail) return <Redirect to={'/'} />
  if (preloader) return <img className={s.spinner} src={spinner} alt="spinner" />

  return (
    <>
      <LogReg
        ModeHandler={ModeHandler}
        regMode={regMode}
        onSubmit={onSubmit}
        displayIcon={displayIcon}
        iconVisibility={iconVisibility}
        pressKeyHandler={pressKeyHandler}
      // failureMsg={failureMsg}
      // successMsg={successMsg}
      />
      <ModalWindow modalIsOpen={modalIsOpen} closeModal={closeModal} msg={msg} logEmail={logEmail} />
    </>
  )
}

export default LogRegContainer
