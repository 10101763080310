import React from 'react'
import ContractContainer from './Contract/ContractContainer'
import AddContractFormContainer from './AddContractForm/AddContractFormContainer'
import AsideContainer from './Aside/AsideContainer'
import s from './MainPage.module.scss'

const MainPage = ({
  contracts, invoices, setAddModeHandler,
  errorAddContractMsg, isOpenAddContractForm
}) => {
  //============ 1. Выбираем данные и передаем компоненту Contract для заголовка ==============
  //======== 2. Сразу-же выбираем данные для компонента Invoice и передаем в Contract =========
  const showData = contracts.map((contr) => {

    const invoice = invoices.filter((inv) => {
      return String(inv.contract[0].contract) === String(contr.contract)
    })
    // console.log(invoice[0])

    return (
      <ContractContainer
        contractId={contr.contract}
        fio={contr.fio}
        invoice={invoice[0]}
        key={contr.contract}
        branch={contr.branch}
      />
    )
  })
  //==============================================================================================

  return (
    <div className={s.main}>
      <div className={s.section}>

        <div className={s.clickAddContract}>
          {isOpenAddContractForm || errorAddContractMsg
            ? <AddContractFormContainer setAddModeHandler={setAddModeHandler} />
            : <div className={s.addContractButton} onClick={setAddModeHandler.bind(null, true)} >
              Додати особовий рахунок
            </div>
          }
        </div>

        {showData.length
          ? showData
          : <div>
            <div className={s.noDataCont}>
              Для відображення інформації додайте особовий рахунок
            </div>
          </div>
        }
      </div>

      <AsideContainer />
    </div>
  )
}

export default MainPage
