import {
  GET_CONTRACTS,
  // DEL_CONTRACT,
  PRELOADER_CONTRACTS,
  PRELOADER,
  ERROR_ADD_CONTRACT_MSG,
  IS_OPEN_ADD_CONTRACT_FORM,
} from '../actionTypes/contractsTypes'

let initialState = {
  contracts: [],
  isOpenAddContractForm: false,
  preloaderContracts: false,
  errorAddContractMsg: '',
  preloader: false
}

const contractsReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTRACTS: {
      return {
        ...state,
        contracts: [...action.payload],
      }
    }

    case PRELOADER_CONTRACTS: {
      return {
        ...state,
        preloaderContracts: action.payload,
      }
    }

    case PRELOADER: {
      return {
        ...state,
        preloader: action.payload,
      }
    }

    // case DEL_CONTRACT: {
    //   return {
    //     ...state,
    //     contracts: state.contracts.filter((e) => e.contract !== action.payload),
    //   }
    // }

    case IS_OPEN_ADD_CONTRACT_FORM: {
      return {
        ...state,
        isOpenAddContractForm: action.payload,
      }
    }

    case ERROR_ADD_CONTRACT_MSG: {
      return {
        ...state,
        errorAddContractMsg: action.payload,
      }
    }

    default:
      return state
  }
}

export default contractsReducers
