import React from 'react'
import { useDispatch } from 'react-redux'
import Modal from 'react-modal'
// import { setLogEmailAC, getFailureMsgAC } from '../../store/actionCreators/userActionCreator'
import { senderEmailActivationTC } from '../../store/actionCreators/userActionCreator'
import s from './ModalWindow.module.scss'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(8, 53, 86, 0.60)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '300px',
    maxWidth: '250px',
    minWidth: '200px',
    padding: '20px'
  }
};

Modal.setAppElement('#root')


// Компонент используется для сообщений после регистрации (успешной и не успешной), 
// при ошибках залогинивания (используется переменная msg ).
// и после повторной отправки письма активации (используется переменная logEmail ).


const ModalWindow = ({ modalIsOpen, closeModal, msg, logEmail }) => {

  const dispatch = useDispatch()

  const senderEmailActivation = () => {
    closeModal()
    dispatch(senderEmailActivationTC({ email: logEmail }))
  }

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >

        <div className={s.modal}>
          {/* <div className={s.container}> */}
          <div>
            <div className={s.title}>Повідомлення</div>
            <div className={s.line}></div>
            <div className={s.message}>
              {msg}
            </div><br />
            {logEmail
              ? <div className={s.sendButton} onClick={senderEmailActivation}>Надіслати лист активації повторно</div>
              : null
            }
          </div>
          <button onClick={closeModal} autoFocus={true}>Закрити</button>
        </div>
      </Modal>
    </div >
  );
}

export default ModalWindow