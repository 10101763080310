import { GET_ARCHIVE, PRELODER_ARCHIVE, DEL_ARCHIVE } from '../actionTypes/paymentArchiveTypes'

const initialState = {
  paymentArchive: {},
  preloader: false
}

const paymentArchiveReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_ARCHIVE: {
      return {
        ...state,
        paymentArchive: {
          ...state.paymentArchive,
          [`${action.payload.contractId}`]: action.payload.arch
        }
      }
    }

    case DEL_ARCHIVE: {
      const newPayments = { ...state.paymentArchive }
      delete newPayments[`${action.payload}`]
      return {
        ...state,
        paymentArchive: { ...newPayments }
      }
    }

    case PRELODER_ARCHIVE: {
      return {
        ...state,
        preloader: action.payload
      }
    }

    default: return state
  }

}

export default paymentArchiveReducer