import React from 'react'
import { NavLink } from 'react-router-dom'
import s from './UserActivation.module.scss'

const UserActivation = () => {

  return <div className={s.activateCont}>
    <div className={s.activateMsg}>
      <div>Ваш обліковий запис активовано.</div>
      <div className={s.link}><NavLink to="/" >Увійти</NavLink></div>
    </div>
  </div>
}

export default UserActivation