import React from 'react'
import CurrentValueFormContainer from './CurrentValueForm/CurrentValueFormContainer'
import { dateConverter } from '../../../../utils.js'
import s from './SendNumber.module.scss'

const SendNumber = ({ theseWaterMeters, contractId }) => {

  // console.log("contractId", contractId)
  // console.log("theseWaterMeters", theseWaterMeters)

  // console.log("theseWaterMeters", theseWaterMeters)

  const showMeters = theseWaterMeters.map((meter) => {

    // console.log("launched")
    // console.log("meter.serial_number", meter.serial_number)
    // console.log("meter", meter)



    // Функция вычисляет дату следующей поверки. Принимает дату прошлой поверки и возвращает ее-же + 4 года
    const nextVerifCreator = () => {
      if (!meter.date_verification) return null
      const nextYear = Number(meter.date_verification.substr(0, 4)) + 4
      const nextVerif = meter.date_verification.replace(meter.date_verification.substr(0, 4), nextYear)
      return nextVerif
    }

    return (
      <div key={meter.metering_device} className={s.water_meter}>

        <div className={s.meterData}>
          <div>
            <div className={s.title}>Тип лічильника</div>
            <div className={s.value}>{meter.type_wm}</div>
          </div>
          <div>
            <div className={s.title}>Лічильник</div>
            <div className={s.value}>{meter.serial_number}</div>
          </div>
          <div>
            <div className={s.title}>Місце встановлення</div>
            <div className={s.value}>{meter.location_name}</div>
          </div>

          <div>
            <div className={s.title}>Дата встановлення</div>
            <div className={s.value}>{dateConverter(meter.date_installation)}</div>
          </div>
          <div>
            <div className={s.title}>Дата повірки</div>
            <div className={s.value}>{dateConverter(meter.date_verification)}</div>
          </div>
          <div>
            <div className={s.title}>Наступна повірка</div>
            <div className={s.value}>{dateConverter(nextVerifCreator(meter.date_verification))}</div>
          </div>
          <div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className={s.line}></div>

        <CurrentValueFormContainer
          metersdata={meter.metersdata}
          // current_value={meter.metersdata.current_value} //====убрать
          metering_device={meter.metering_device}
          // serial_number={meter.serial_number}
          preloaderSendValue={meter.preloaderSendValue}
          location_name={meter.location_name}
          sendNumberMessage={meter.sendNumberMessage}
          contractId={contractId}
        />
      </div>
    )
  })

  return (<div className={s.water_meterContainer}>
    {showMeters}
  </div>)
}

export default SendNumber
