import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { NavLink } from 'react-router-dom'
import { required, email, password, matchingPasswd2 } from '../../validators/validators'
import { Input } from '../FormsControl/FormsControl'
import s from '../LogReg/LogReg.module.scss'

const PasswdReset = ({ handleSubmit, successResetMsg, failureResetMsg, displayIcon,
  iconVisibility, clearMessages, pressKeyHandler }) => {

  // if (successResetMsg) {
  //   return (
  //     <div className={s.container}>
  //       <div className={s.form}>
  //         <div className={s.warning}>{successResetMsg}</div>
  //         <div className={s.linkResetWindow}><NavLink onClick={clearMessages} to="/" >Увійти</NavLink></div>
  //       </div>
  //     </div>
  //   )
  // }

  return (
    <div className={s.container}>
      <form action="" className={s.form} onSubmit={handleSubmit}>
        <div className={s.titleForm}>Відновлення пароля</div>

        <Field
          className="field"
          name="email"
          placeholder="Електронна пошта"
          component={Input}
          type="email"
          validate={[required, email]}
          label="email"
          normalize={pressKeyHandler}
        />

        <Field
          className="field"
          name="password"
          placeholder="Новий пароль"
          component={Input}
          type={iconVisibility ? "text" : "password"}
          validate={[required, password]}
          label="password"
          displayIcon={displayIcon}
          iconVisibility={iconVisibility}
          normalize={pressKeyHandler}
        />

        <Field
          className="field"
          name="password2"
          placeholder="Підтвердження"
          component={Input}
          type={iconVisibility ? "text" : "password"}
          validate={[required, matchingPasswd2]}
          label="password"
          displayIcon={displayIcon}
          iconVisibility={iconVisibility}
          normalize={pressKeyHandler}
        />

        {/* {failureResetMsg && <div className={s.warning}>{failureResetMsg}</div>} */}

        <button type="submit" className={s.buttonForm}>
          Змінити пароль
        </button>

        <div className={s.navlinkBack}>
          <NavLink to="/">
            &#8592; Повернутися
          </NavLink>
        </div>

      </form>
    </div>
  )
}

const ResetReduxForm = reduxForm({ form: 'resetP' })(PasswdReset)

export default ResetReduxForm
