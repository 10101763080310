import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import { reducer as formReducer } from 'redux-form'
import thunkMiddleware from 'redux-thunk'
import userReducer from './reducers/userReducer'
import contractsReducer from './reducers/contractsReducer'
import invoicesReducer from './reducers/invoicesReducer'
import waterMetersReducer from './reducers/waterMetersReducer'
import paymentArchiveReducer from './reducers/paymentArchiveReducer'
import indicationArchiveReducer from './reducers/indicationArchiveReducer'

export { RESET_REDUX } from './actionTypes/userTypes'

let reducers = combineReducers({
  userPage: userReducer,
  contractsPage: contractsReducer,
  invoicesPage: invoicesReducer,
  waterMetersPage: waterMetersReducer,
  paymentArchivePage: paymentArchiveReducer,
  indicationArchivePage: indicationArchiveReducer,
  form: formReducer,
})


const rootReducer = (state, action) => {
  if (action.type === 'RESET_REDUX') {
    state = undefined
  }

  return reducers(state, action)
}




const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)))

export default store
