import React from 'react'
import Invoice from './Invoice'
import { useSelector } from 'react-redux'
import NoData from '../NoData/NoData'
import Preloader from '../Preloader/Preloader'

const InvoiceContainer = ({ invoice, branch }) => {

  const preloader = useSelector((state) => state.invoicesPage.preloader)
  const currentDate = useSelector((state) => state.invoicesPage.current_date)
  const bankDetails = useSelector((state) => state.userPage.bankDetails)


  const saverPdf = (payload) => {
    payload.currentDate = currentDate
    payload.bankDetails = bankDetails

    import("./GeneratePDF/generatePDF.js")
      .then(({ default: generatePDF }) => {
        generatePDF(payload)
      })
  }

  // console.log(preloader)
  if (preloader && !invoice) return <Preloader />
  if (!invoice) return <NoData />
  return <Invoice
    invoice={invoice}
    saverPdf={saverPdf}
    branch={branch}
  />
}

export default InvoiceContainer