import React from "react"
import { dateConverter } from '../../../../../../utils'
import s from "../../../PaymentArchive/OneYearArchive/OneYear.module.scss"

const OneYearIndicArchive = ({ oneYear }) => {

  // console.log("OneYearIndicArchive", oneYear)

  const data = oneYear.map((pay) => {

    // const dt = new Date(pay.date_editing)
    // const mounthString = dt.toLocaleString('uk', { month: 'long' })
    return (
      <tr key={pay.date_editing}>
        {/* <td>{mounthString}</td> */}
        <td className={s.row}>{dateConverter(pay.date_editing)}</td>
        <td className={s.row}>{pay.current_value}</td>
      </tr>
    )
  })

  return (
    <table className={s.oneYearTable} >
      <thead>
        <tr>
          {/* <th>Звітний місяць</th> */}
          <th className={s.tableHead}>Станом на</th>
          <th className={s.tableHead}>Показання</th>
        </tr>
      </thead>
      <tbody>
        {data}
      </tbody>
    </table>
  )
}

export default OneYearIndicArchive