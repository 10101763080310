import React from 'react';
import Modal from 'react-modal';
import { delContractMsg, confirmationSendNumb } from '../../../../constants'
import s from './ModalVerification.module.scss'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(8, 53, 86, 0.60)',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '300px',
    maxWidth: '250px',
    // minWidth: '200px',
    padding: '20px',
  }
}

Modal.setAppElement('#root')


const ModalVerification = ({
  modalIsOpen, closeModal, delContractHandler, operation,
  setCurentValueHandler, dataModalVerification }) => {

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className={s.modalContainer}>

          <div>
            <div className={s.title}>Повідомлення</div>
            <div className={s.line}></div>
            <div className={s.message}>

              {operation === 'delContract'
                ? <span>{delContractMsg}</span>
                : null
              }

              {operation === 'sendNumber'
                ? <span>{confirmationSendNumb} <br /> {dataModalVerification.current_value} м.куб.?</span>
                : null
              }

            </div>
          </div>

          <div className={s.buttonContainer}>
            {operation === 'delContract'
              ? <button onClick={delContractHandler} autoFocus={true}>Так</button>
              : null
            }

            {operation === 'sendNumber'
              ? <button onClick={setCurentValueHandler.bind(null, dataModalVerification)} autoFocus={true}>Так</button>
              : null
            }

            <button onClick={closeModal} >Скасувати</button>
          </div>
        </div>

      </Modal>
    </div >
  )
}

export default ModalVerification