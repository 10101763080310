import { indicationArchive } from '../../api/api'
import { GET_INDICATION_ARCHIVE, DEL_INDICATION_ARCHIVE, PRELODER_INDICATION_ARCHIVE } from '../actionTypes/indicationArchiveTypes'



// ------------------- Action Creators --------------------
// ------------ Get archive of indication AC -------------
export const getIndicationArchiveAC = (payload) => {
  return { type: GET_INDICATION_ARCHIVE, payload }
}

//-----------------Delete one indication archive -----------------------
export const delIndicatArchiveAC = (payload) => {
  return {
    type: DEL_INDICATION_ARCHIVE,
    payload
  }
}

//-----------------Preloader archive -----------------------
export const preloderIndicArchAC = (payload) => {
  return {
    type: PRELODER_INDICATION_ARCHIVE,
    payload
  }
}




// ------------------- Thunk Creators --------------------
// ------------ Get archive of indication TC -------------

export const getIndicationArchiveTC = (payload) => {
  // console.log("getIndicationtArchiveTC payload: ", payload)
  return (dispatch) => {
    dispatch(preloderIndicArchAC(true))
    indicationArchive.getIndicationArchiveAPI(payload)
      .then((res) => {
        // console.log("getIndicationtArchiveTC res.data: ", res.data.meterdata_archive)

        const newRes = {
          meterdata_archive: [...res.data.meterdata_archive],
          contractId: payload.contractId
        }
        // console.log("newRes", newRes)
        dispatch(preloderIndicArchAC(false))
        dispatch(getIndicationArchiveAC(newRes))
      })
      .catch((err) => {
        dispatch(preloderIndicArchAC(false))
        console.log("getIndicationArchiveTC error: ", err)
      })
  }
}