import React from 'react'
import { useSelector } from 'react-redux'
import SendNumber from './SendNumber'
import Preloader from '../Preloader/Preloader'
import s from './SendNumber.module.scss'

const SendNumberContainer = ({ theseWaterMeters, contractId }) => {
  const preloader = useSelector((state) => state.waterMetersPage.preloaderWMs)

  // console.log("theseWaterMeters", theseWaterMeters)

  if (preloader) return <Preloader />
  if (!theseWaterMeters.length) return <div className={s.sendNumber_NoData}>Лічильники відсутні</div>
  return (<>
    <SendNumber theseWaterMeters={theseWaterMeters} contractId={contractId} />
  </>)
}

export default SendNumberContainer
