import {
  AUTHORIZATION,
  PRELOADER,
  SUCCESS_MSG,
  FAILURE_MSG,
  GET_NOTICES,
  DEL_NOTICES,
  PRELOADER_NOTICES,
  SUCCESS_RESET_MSG,
  FAILURE_RESET_MSG,
  SET_LOG_EMAIL,
  GET_BANK_DETAILS
} from '../actionTypes/userTypes'

const getCookie = (variable) => {
  const allcookies = document.cookie
  const pos = allcookies.indexOf(`${variable}=`)
  if (pos !== -1) {
    var start = pos + variable.length + 1
    var end = allcookies.indexOf(';', start)

    if (end === -1) {
      end = allcookies.length
    }

    const value = allcookies.substring(start, end)
    return value
  }
}

let initialState = {
  userToken: getCookie("userToken"),
  userEmail: getCookie("userEmail"),
  preloader: false,
  successMsg: '',
  failureMsg: '',
  successResetMsg: '',
  failureResetMsg: '',
  notices: [],
  preloaderNotices: false,
  logEmail: '',
  bankDetails: {}
}

const userReducers = (state = initialState, action) => {
  switch (action.type) {
    case AUTHORIZATION: {
      return {
        ...state,
        userEmail: action.payload.userEmail,
        userToken: action.payload.userToken
      }
    }

    case PRELOADER: {
      return {
        ...state,
        preloader: action.payload,
      }
    }

    case SUCCESS_MSG: {
      return {
        ...state,
        successMsg: action.payload,
      }
    }

    case FAILURE_MSG: {
      return {
        ...state,
        failureMsg: action.payload,
      }
    }

    case SUCCESS_RESET_MSG: {
      return {
        ...state,
        successResetMsg: action.payload,
      }
    }

    case FAILURE_RESET_MSG: {
      return {
        ...state,
        failureResetMsg: action.payload,
      }
    }

    case DEL_NOTICES: {
      return {
        ...state,
        notices: [],
      }
    }

    case GET_NOTICES: {
      return {
        ...state,
        notices: [...state.notices, ...action.payload],
      }
    }

    case PRELOADER_NOTICES: {
      return {
        ...state,
        preloaderNotices: action.payload,
      }
    }

    case SET_LOG_EMAIL: {
      return {
        ...state,
        logEmail: action.payload
      }
    }

    case GET_BANK_DETAILS: {
      return {
        ...state,
        bankDetails: action.payload
      }
    }

    default: return state
  }
}

export default userReducers
