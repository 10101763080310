import {
  GET_CONTRACTS,
  // ADD_CONTRACT,
  // DEL_CONTRACT,
  PRELOADER_CONTRACTS,
  // SET_CURRENT_VALUE_MSG,
  PRELOADER,
  ERROR_ADD_CONTRACT_MSG,
  IS_OPEN_ADD_CONTRACT_FORM
} from '../actionTypes/contractsTypes'
import { contracts } from '../../api/api'
import { getInvoicesTC } from './invoicesActionCreator'
import { getWaterMetersTC } from './waterMetersActionCreator'
import { delArchiveAC } from './paymentArchiveActionCreator'
import { delIndicatArchiveAC } from './indicationArchiveActionCreator'
import { getNoticesTC, delNoticesAC } from './userActionCreator'

// ------------------- Action Creators --------------------
// ------------ Get primary consumer data AC -------------
export const getContractsAC = (payload) => {
  return { type: GET_CONTRACTS, payload }
}

export const preloaderAddContractAC = (payload) => {
  return { type: PRELOADER_CONTRACTS, payload }
}

// ---------------- Delete contract AC ------------------
// export const delContractAC = (payload) => {
//   return { type: DEL_CONTRACT, payload }
// }

// //----------------Set message display AC------------------
// // export const setCurrentValueMsgAC = (payload) => {
// //   return { type: SET_CURRENT_VALUE_MSG, payload }
// // }

export const preloaderAC = (payload) => {
  return { type: PRELOADER, payload }
}

//----------------Set error AddContract message display AC------------------
export const errorAddContractMsgAC = (payload) => {
  return { type: ERROR_ADD_CONTRACT_MSG, payload }
}

//----------------Opening form for adding a contract AC------------------
export const openingAddContractFormAC = (payload) => {
  // console.log("openingAddContractFormAC", payload)
  return { type: IS_OPEN_ADD_CONTRACT_FORM, payload }
}


// ------------------- Thunk Creators --------------------
// ---------------- Get all contracts TC--------------------
export const getContractsTC = (payload) => {
  // console.log("Start getContractsTC - 1")
  return (dispatch) => {
    dispatch(preloaderAC(true))
    return contracts
      .getContractsAPI(payload)
      .then((res) => {
        dispatch(preloaderAC(false))
        // dispatch(delNoticesAC())
        dispatch(getContractsAC(res.data.contracts))
        // console.log("Finish getContractsTC - 1")
      })

      .catch((err) => {
        console.log('err:', err)
        dispatch(preloaderAC(false))
        // console.log('err.response.data:', err.response.data)
        // console.log('err.response.status:', err.response.status)
        // dispatch(getRegErrorMsgAC('msg'))
      })
  }
}

// ---------------- Adding a contract TC--------------------
export const addContractTC = (payload) => {
  return (dispatch) => {
    dispatch(preloaderAddContractAC(true))
    contracts
      .addContractAPI(payload)
      .then((res) => {
        dispatch(preloaderAddContractAC(false))
        // dispatch(errorAddContractMsgAC(''))
        // dispatch(getWaterMetersTC(res.data.contract.contract))
        // dispatch(addContractAC(res.data.contract))
        // dispatch(resetReduxAC())
        dispatch(delNoticesAC())
        // })
        // .then(() => {
        return Promise.all([
          dispatch(getContractsTC(payload.userToken)),
          dispatch(getNoticesTC(payload.userToken)),
          dispatch(getInvoicesTC(payload.userToken))
        ])

      })
      .then(() => {
        dispatch(getWaterMetersTC(payload.userToken))
        dispatch(openingAddContractFormAC(false))
      })
      .catch((err) => {
        dispatch(preloaderAddContractAC(false))
        // console.log('err:', err)
        console.log('err.response.data:', err.response.data.message)
        dispatch(errorAddContractMsgAC(err.response.data.message))
      })
  }
}

//--------------------- Deletion of a contract TC-------------------------
export const delContractTC = (payload) => {
  return (dispatch) => {
    // dispatch(preloaderContractsAC(true))
    contracts.delContractAPI(payload)
      .then((res) => {
        // dispatch(delContractAC(payload.apiData.contract))
        // dispatch(delInvoiceAC(payload.apiData.contract))
        // dispatch(delWaterMeterAC(payload.apiData.contract))
        // dispatch(delArchiveAC(payload.apiData.contract))
        // // dispatch(delPaymentsAC(payload.apiData.contract))
        // // dispatch(preloaderContractsAC(false))
        // dispatch(resetReduxAC())
        dispatch(delNoticesAC())
        dispatch(delArchiveAC(payload.apiData.contract))
        dispatch(delIndicatArchiveAC(payload.apiData.contract))
        // })
        // .then(() => {
        return Promise.all([
          dispatch(getContractsTC(payload.userToken)),
          dispatch(getNoticesTC(payload.userToken)),
          dispatch(getInvoicesTC(payload.userToken))
        ])
      })
      .then(() => {
        dispatch(getWaterMetersTC(payload.userToken))
      })
      .catch((err) => {
        console.log('err:', err)
      })
  }
}

// ---------------- Set current value TC--------------------
// export const setCurrentValueTC = (payload) => {
//   return (dispatch) => {
//     dispatch(preloaderSetCurrentValueAC({
//       contract: payload.data.contract,
//       serial_number: payload.data.serial_number,
//       preloaderSetCurrentValue: true
//     }))
//     contracts
//       .setCurrentValueAPI(payload)
//       .then((res) => {
//         dispatch(preloaderSetCurrentValueAC({
//           contract: payload.data.contract,
//           serial_number: payload.data.serial_number,
//           preloaderSetCurrentValue: false
//         }))
//         dispatch(setCurrentValueMsgAC({
//           contract: payload.data.contract,
//           serial_number: payload.data.serial_number,
//           msg: true
//         }))
//       })
//       .catch((err) => {
//         dispatch(preloaderSetCurrentValueAC({
//           contract: payload.data.contract,
//           serial_number: payload.data.serial_number,
//           preloaderSetCurrentValue: false
//         }))
//         console.log('err:', err)
//         dispatch(setCurrentValueMsgAC({
//           contract: payload.data.contract,
//           serial_number: payload.data.serial_number,
//           msg: false
//         }))
//         // setCurrentValueMsgAC(false)
//         // console.log('err.response.data:', err.response.data)
//         // console.log('err.response.status:', err.response.status)
//         // dispatch(getRegErrorMsgAC('msg'))
//       })
//   }
// }