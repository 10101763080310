import {
  AUTHORIZATION,
  PRELOADER,
  // PASWORD_RESET,
  SUCCESS_MSG,
  FAILURE_MSG,
  GET_NOTICES,
  PRELOADER_NOTICES,
  SUCCESS_RESET_MSG,
  FAILURE_RESET_MSG,
  RESET_REDUX,
  DEL_NOTICES,
  SET_LOG_EMAIL,
  GET_BANK_DETAILS
} from '../actionTypes/userTypes'
import { user, invoices } from '../../api/api'

// ------------------------- Action Creators -------------------------
// ------------------------- Authorization AC -------------------------
export const authorizationAC = (payload) => {
  return { type: AUTHORIZATION, payload }
}

// ------------------------- Preloader AC -------------------------
export const preloaderAC = (payload) => {
  return { type: PRELOADER, payload }
}

// ------------------------- Password reset AC -------------------------
// export const passwdResetAC = (payload) => {
//   return { type: PASWORD_RESET, payload }
// }

// ----------------------- Set success message AC -----------------
export const getSuccessMsgAC = (payload) => {
  return { type: SUCCESS_MSG, payload }
}

// ----------------------- Set error message AC -----------------
export const getFailureMsgAC = (payload) => {
  return { type: FAILURE_MSG, payload }
}

// ----------------------- Set success reset password message AC -----------------
export const getSuccessResetMsgAC = (payload) => {
  return { type: SUCCESS_RESET_MSG, payload }
}

// ----------------------- Set error reset password  message AC -----------------
export const getFailureResetMsgAC = (payload) => {
  return { type: FAILURE_RESET_MSG, payload }
}

// ----------------------- Get Notices AC -----------------
export const getNoticesAC = (payload) => {
  return { type: GET_NOTICES, payload }
}

// ----------------------- Delete Notices AC -----------------
export const delNoticesAC = (payload) => {
  return { type: DEL_NOTICES, payload }
}

// ------------------------- Preloader notices AC -------------------------
export const preloaderNoticesAC = (payload) => {
  return { type: PRELOADER_NOTICES, payload }
}

// ------------------------- Reset Redux AC -------------------------
export const resetReduxAC = (payload) => {
  return { type: RESET_REDUX, payload }
}

// ------------------------- Setting email on login AC -------------------------
export const setLogEmailAC = (payload) => {
  return { type: SET_LOG_EMAIL, payload }
}

// ------------------------- Setting bank details AC -------------------------
const setBankDetailsAC = (payload) => {
  return { type: GET_BANK_DETAILS, payload }
}


// ------------------------- Thunk Creators  -------------------------
// ------------------------- Registration TC ---------------------
export const authorizationTC = (payload) => {
  return (dispatch) => {
    dispatch(preloaderAC(true))
    if (payload.profile.phone) {
      user
        .registrationAPI(payload)
        .then((res) => {
          if (res.statusText === 'Created') {
            dispatch(preloaderAC(false))
            dispatch(getSuccessMsgAC(res.data.email)) //
            // dispatch(getRegSuccessfulMsgAC(res.data.email))
          }
        })
        .catch((err) => {
          dispatch(preloaderAC(false))
          // console.log('err.response.data.error:', err.response.data.error[0])
          // console.log('err.response.data.mail:', err.response.data.email[0])
          let msgErr
          if (err.response.data.error) {
            msgErr = err.response.data.error[0]
          } else {
            msgErr = err.response.data.email[0]
          }
          dispatch(getFailureMsgAC(msgErr)) //

        })
    } else { // ------------------------- Authorization TC ---------------------
      user
        .authorizationAPI(payload)
        .then((res) => {
          dispatch(preloaderAC(false))
          // document.cookie = `userToken=${res.data.token}; max-age=86400; SameSite = Strict;` // max-age - 1 сутки
          // document.cookie = `userEmail=${res.data.email}; max-age=86400; SameSite = Strict;`

          document.cookie = `userToken=${res.data.token}; max-age=1800; SameSite = Strict;` // max-age - 30 минут
          document.cookie = `userEmail=${res.data.email}; max-age=1800; SameSite = Strict;`

          dispatch(authorizationAC({ userToken: res.data.token, userEmail: res.data.email }))
          dispatch(delNoticesAC())
          // return res.data.token
        })
        .catch((err) => {
          dispatch(preloaderAC(false))
          dispatch(getFailureMsgAC(err.response.data.error))
          if (err.response.data.error === 'Користувач не активований.') dispatch(setLogEmailAC(payload.email))
        })
    }
  }
}

// ----------------- Get notices TC ---------------------
export const getNoticesTC = (payload) => {
  // console.log("Start getNoticesTC - 2")
  return (dispatch) => {
    dispatch(preloaderNoticesAC(true))
    return user
      .getNoticesAPI(payload)
      .then((response) => {
        dispatch(preloaderNoticesAC(false))
        dispatch(getNoticesAC(response.data.notices))
        // console.log("Finish getNoticesTC - 2")
      })
      .catch((err) => {
        dispatch(preloaderNoticesAC(false))
        dispatch(preloaderAC(false))
        console.log('err.response.data:', err.response.data.error)
        // console.log('err:', err)
        // console.log('rerr.response.data:', err.response.data)
        // console.log('rerr.response.data.detail:', err.response.data.detail)
        // console.log('err.response.status:', err.response.status)
      })
  }
}

// ------------------------- Password reset TC -------------------------
export const passwdResetTC = (payload) => {
  console.log("reducer", payload.newPasswd.email)
  return (dispatch) => {
    dispatch(getFailureResetMsgAC(''))
    dispatch(preloaderAC(true))
    user.passwdResetAPI(payload.newPasswd)
      .then(() => {
        // console.log("Успешно")
        dispatch(getSuccessResetMsgAC(payload.msg))
        // })
        // .then(() => {
        dispatch(preloaderAC(false))
      })
      .catch((err) => {
        dispatch(preloaderAC(false))
        console.log('err.response.data:', err.response.data.error)
        // dispatch(getLogErrorMsgAC(err.response.data.error))
        if (err.response.data.error === "Користувач не активований.") {
          dispatch(getFailureMsgAC(err.response.data.error))
          dispatch(setLogEmailAC(payload.newPasswd.email))
        }
        else dispatch(getFailureResetMsgAC(err.response.data.error))
      })
  }
}


// ------------------------- Re-activation letter  -------------------------
export const senderEmailActivationTC = (payload) => {
  return (dispatch) => {
    user.senderEmailActivationAPI(payload)
      .then((res) => {
        dispatch(getSuccessMsgAC(res.data))
      })
      .catch((err) => {
        console.log("Re-activation error: ", err)
      })
  }
}

export const getBankDetailsTC = (payload) => {
  return (dispatch) => {
    invoices.getBankDetailsAPI(payload)
      .then((res) => {
        dispatch(setBankDetailsAC(res.data.bank_dеtail))
      })
      .catch((err) => {
        console.log("getBankDetailsTC error: ", err)
      })
  }
}


// ------------------------- Activation TC -------------------------
// export const activationTC = (payload) => {
//   return (dispatch) => {
//     // dispatch(preloaderAC(true))
//     // console.log("TC", payload)
//     // http://localhost:3000/cabinet/activate/параметр1/параметр2/
//     user.activationMsgAPI(payload)
//       .then((data) => {
//         // console.log('data:', data.data)
//         // dispatch(activationMsgAC(data.data.message))
//         dispatch(getSuccessMsgAC(data.data.message)) //
//       })
//       .catch((err) => {
//         // dispatch(preloaderAC(false))
//         // console.log('rerr.response.data:', err.response.data.error)
//         // dispatch(getLogErrorMsgAC(err.response.data.error))
//         dispatch(getFailureMsgAC(err.response.data.message))
//         // console.log('rerr.response.data.message:', err.response.data.message)
//       })
//   }
// }
