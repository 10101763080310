import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { required, number10 } from '../../../validators/validators'
import { Input } from '../../FormsControlLight/FormsControlLight'
import Preloader from '../Contract/Preloader/Preloader'
import s from './AddContractForm.module.scss'

const AddContractForm = ({
  handleSubmit, setAddModeHandler, preloaderContracts, pressKeyHandlerString, pressKeyHandlerNumber
}) => {

  if (preloaderContracts) return <Preloader />
  return (
    <>
      <form action="" onSubmit={handleSubmit} className={s.addContractForm}>

        <Field
          className="field"
          name="contract"
          // autoFocus={true}
          placeholder="Номер особового рахунку"
          component={Input}
          type="text"
          validate={[required, number10]}
          // onChange={(e) => { pressKeyHandler(e.target.value) }}
          normalize={pressKeyHandlerNumber}
        />

        <Field
          className="field"
          name="fio"
          placeholder="Прізвище власника"
          component={Input}
          type="text"
          validate={[required]}
          normalize={pressKeyHandlerString}
        />

        <div className={s.buttonContainer}>
          <button type="submit" className={s.buttonForm}>Додати</button>
          <button onClick={setAddModeHandler.bind(null, false)} className={s.buttonForm}>Закрити</button>
        </div>
      </form>
    </>
  )
}

const ReduxAddContractForm = reduxForm({ form: 'addContract' })(AddContractForm)

export default ReduxAddContractForm
