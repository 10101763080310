import React from 'react'
import mail from '../../images/letter.svg'
import phone from '../../images/phone.svg'
import showPass from '../../images/show_passwordLight.svg'
import hidePass from '../../images/hide_passwordLight.svg'
import s from './FormsControl.module.scss'

// export const TextArea = ({input, meta, ...props}) => {
//    return (
//       <div className={formsStyles.formControl + " " + (meta.touched && meta.error ? formsStyles.error : ' ')}>
//          <textarea {...input} {...props} />
//          {meta.touched && meta.error && <span>{meta.error}</span>}
//       </div>
//    )
// }

export const Input = ({ input, meta, label, iconVisibility, displayIcon, ...props }) => {
  return (
    <div className={s.formControl + ' ' + (meta.touched && meta.error ? s.error : ' ')}>
      <div className={s.fieldContainer} >
        <input {...input} {...props} />
        <div className={s.tip}>{props.placeholder}</div>
        {label === 'password' ? <img src={iconVisibility ? hidePass : showPass} className={s.hidePass} onClick={displayIcon} alt="icon" /> : null}
        {/* <img src={label === 'password' ? lock : label === 'email' ? mail : null} className={s.fieldIcon} /> */}
        {label === 'email' ? <img src={mail} className={s.fieldIcon} alt="icon" /> : null}
        {label === 'phone' ? <img src={phone} className={s.fieldIcon} alt="icon" /> : null}
      </div>
      <div className={meta.touched && meta.error ? s.isError : s.notError}>{meta.error} </div>
    </div>
  )
}

export const Checkbox = ({ input, meta, label, ...props }) => {
  return (
    <div className={s.formControl + ' ' + (meta.touched && meta.error ? s.error : ' ')}>
      <div className={s.checkboxContainer} >
        <input {...input} {...props} />
        <span> Згоден на обробку персональних даних</span>
        {/* <img src={label == 'password' ? lock : label == 'email' ? mail : null} /> */}
      </div>
      <div className={meta.touched && meta.error ? s.isError : s.notError}>{meta.error} </div>
    </div>
  )
}