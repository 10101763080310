import React, { useState } from 'react'
import MonthArchive from './Month'
// import s from './OneService.module.scss'

const MonthContainer = ({ monthDetails, months }) => {
  
  const [istMonthOpen, toggletMonthMenu] = useState(false)
  const toggleMenuMode = (val) => {
    toggletMonthMenu(val);
  }

  monthDetails.sort((a, b) => (a.service_type > b.service_type) ? 1 : (a.service_type === b.service_type) ? ((a.service_id > b.service_id) ? 1 : -1) : -1 )

  return (
    <MonthArchive monthDetails={monthDetails} months={months} istMonthOpen={istMonthOpen} toggleMenuMode={toggleMenuMode} />
  )
}

export default MonthContainer
