import { GET_ARCHIVE, PRELODER_ARCHIVE, DEL_ARCHIVE } from '../actionTypes/paymentArchiveTypes'
import { paymentArchive } from '../../api/api'


//----------------- Action creators ---------------------
//-----------------Get one archive -----------------------
const getArchiveAC = (payload) => {
  return {
    type: GET_ARCHIVE,
    payload
  }
}


//-----------------Delete one archive -----------------------
export const delArchiveAC = (payload) => {
  return {
    type: DEL_ARCHIVE,
    payload
  }
}



//-----------------Preloader archive -----------------------
export const preloderArchAC = (payload) => {
  return {
    type: PRELODER_ARCHIVE,
    payload
  }
}

//-------------------Thunk creators ------------------------
export const getArchiveTC = (payload) => {
  return (dispatch) => {
    dispatch(preloderArchAC(true))
    paymentArchive
      .getArchiveAPI(payload)
      .then((res) => {

        dispatch(preloderArchAC(false))
        dispatch(getArchiveAC({
          arch: res.data.archive,
          contractId: payload.contractId
        }))
      })
      .catch((err) => {
        dispatch(preloderArchAC(false))
        console.log(err)
      })

  }
}