import * as axios from 'axios'

const instance = axios.create({
 baseURL: 'https://cabinet.teploseti.zp.ua/api/cabinet', // real server
  //baseURL: 'http://127.0.0.1:8000/cabinet',   // test server
  // baseURL: 'http://192.168.0.76/api/cabinet',  // test server
  headers: { 'Content-Type': 'application/json' },
})


//------------------------- User API ------------------------------
export const user = {
  registrationAPI(payload) {
    return instance.post('/registration', JSON.stringify(payload))
  },

  authorizationAPI(payload) {
    return instance.post('/login', JSON.stringify(payload))
  },

  passwdResetAPI(payload) {
    return instance.post('/reset-password', JSON.stringify(payload))
  },

  // Повторная активация по инициативе пользователя.
  senderEmailActivationAPI(payload) {
    return instance.post(`/activation-link/`, JSON.stringify(payload))
  },

  getNoticesAPI(payload) {
    return instance.get(`/notices/`, {
      headers: { 'Authorization': `Token ${payload}` },
    })
  }
}

//------------------------- Contracts API ------------------------------

export const contracts = {

  getContractsAPI(payload) {
    return instance.get('/contracts/', {
      headers: { 'Authorization': `Token ${payload}` },
    })
  },

  addContractAPI(payload) {
    return instance.put('/contracts/', JSON.stringify(payload.dataForm), {
      headers: { 'Authorization': `Token ${payload.userToken}` },
    })
  },

  delContractAPI(payload) {
    return instance.put('/contracts/', JSON.stringify(payload.apiData), {
      headers: { 'Authorization': `Token ${payload.userToken}` },
    })
  }
}

//------------------------- Invoices API ------------------------------
export const invoices = {

//invoices
  getInvoicesAPI(payload) {
    //console.log("api get invioce", payload)
    return instance.get(`/invoices/`, {
      headers: { 'Authorization': `Token ${payload}` },
  })
},

  getBankDetailsAPI(payload) {
    return instance.get('/bank-details/', {
      headers: { 'Authorization': `Token ${payload}` },
    })
  }
}

// http://192.168.0.76/api/cabinet/bank-dеtails/

//------------------------- Water meters API ------------------------------
export const waterMeters = {

  // getWaterMetersAPI(payload) {
  //   return instance.get(`/watermeters/`, {
  //     headers: { 'Authorization': `Token ${payload}` },
  //   })
  // },

  getWaterMetersAPI(payload) {
    return instance.get(`/metering-devices/`, {
      headers: { 'Authorization': `Token ${payload}` },
    })
  },

  // setCurrentValueAPI(payload) {
  //   return instance.put('/metersdata/', JSON.stringify(payload.data), {
  //     headers: { 'Authorization': `Token ${payload.userToken}` },
  //   })
  // },

  setCurrentValueAPI(payload) {
    return instance.put('/meterdata/', JSON.stringify(payload.data), {
      headers: { 'Authorization': `Token ${payload.userToken}` },
    })
  }


}

// ------------------------- Payment archive API ----------------------------------
export const paymentArchive = {
  getArchiveAPI(payload) {
    return instance.get(`/contract/${payload.contractId}/archive/`, {
      headers: { 'Authorization': `Token ${payload.userToken}` },
    })
  },
}

// ------------------------- Indicationt archive API ----------------------------------
export const indicationArchive = {

  getIndicationArchiveAPI(payload) {
    // console.log("getIndicationtArchiveAPI: ", payload)
    return instance.get(`/contract/${payload.contractId}/meterdata/`, {
      headers: { 'Authorization': `Token ${payload.userToken}` },
    })
  }

}