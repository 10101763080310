import React, { useState, useEffect } from 'react'
import AddContractForm from './AddContractForm'
import { useSelector, useDispatch } from 'react-redux'
import { addContractTC, errorAddContractMsgAC } from '../../../store/actionCreators/contractsActionCreator'
import ModalWindow from '../../ModalWindow/ModalWindow'
// import spinner from '../../../images/spinner.svg'
// import s from './AddContractForm.module.scss'

const AddContractFormContainer = ({ setAddModeHandler }) => {
  const userEmail = useSelector((state) => state.userPage.userEmail)
  const userToken = useSelector((state) => state.userPage.userToken)
  const preloaderContracts = useSelector((state) => state.contractsPage.preloaderContracts)
  const messageForModal = useSelector((state) => state.contractsPage.errorAddContractMsg)
  const dispatch = useDispatch()
  const contracts = useSelector((state) => state.contractsPage.contracts)

  //Удаляем все кроме цифр введенные пользователем
  const pressKeyHandlerNumber = (value) => value.replace(/\D/g, '')
  //Удаляем пробелы
  const pressKeyHandlerString = (value) => value.replace(/\s/g, '')

  const onSubmit = (formData) => {
    // Если контракт уже добавлен - выводим сообщение, иначе делаем запрос на сервер для добавления контракта
    let already = false
    contracts.forEach((contr) => {
      if (contr.contract === Number(formData.contract)) already = true
    })

    if (already) {
      dispatch(errorAddContractMsgAC(`Особовий рахунок ${formData.contract} вже приєднаний до Вашого аккаунту`))
      setIsOpen(true)
    } else {
      const dataForm = {
        action: 'add',
        email: userEmail,
        contract: formData.contract,
        fio: formData.fio
      }

      dispatch(addContractTC({ dataForm, userToken }))
    }
  }

  //======================================== Modal Window ============================
  const color = 'red'
  const [modalIsOpen, setIsOpen] = useState(false)

  const closeModal = () => {
    dispatch(errorAddContractMsgAC(''))
    setIsOpen(false)
  }
  //======================================= / Modal Window ==========================

  // Если сервер прислал ошибку при прикреплении л/с - то отображаем модальное окно с текстом ошибки
  useEffect(() => {
    if (messageForModal) setIsOpen(true)
  }, [messageForModal])

  return <>
    <AddContractForm onSubmit={onSubmit} setAddModeHandler={setAddModeHandler}
      preloaderContracts={preloaderContracts} pressKeyHandlerString={pressKeyHandlerString}
      pressKeyHandlerNumber={pressKeyHandlerNumber} />
    <ModalWindow modalIsOpen={modalIsOpen} closeModal={closeModal} msg={messageForModal} color={color} />
  </>
}

export default AddContractFormContainer
