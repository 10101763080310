import {
  GET_INVOICES,
  // DEL_INVOICE,
  PRELOADER,
  GET_CURRENT_DATE
} from '../actionTypes/invoicesTypes'

let initialState = {
  invoices: [],
  current_date: '',
  preloader: false
}

const invoiceReducers = (state = initialState, action) => {
  switch (action.type) {

    case GET_INVOICES: {
      return {
        ...state,
        invoices: [...action.payload]
      }
    }

    case GET_CURRENT_DATE: {
      return {
        ...state,
        current_date: action.payload
      }
    }

    // case DEL_INVOICE: {
    //   return {
    //     ...state,
    //     invoices: state.invoices.filter((i) => i.contract !== action.payload)
    //   }
    // }

    case PRELOADER: {
      return {
        ...state,
        preloader: action.payload
      }
    }

    default:
      return state
  }
}

export default invoiceReducers
