import React from 'react'
import s from './Footer.module.scss'

const Footer = ({ userEmail }) => {
  return (
    <div className={userEmail ? s.footerIn : s.footer}>
      <div>
        © 2020 - 2024 Концерн «Міські теплові мережі».
      </div>

    </div>
  )
}

export default Footer
