import React from 'react'
import s from './Invoice.module.scss'

const Invoice = ({ invoice, saverPdf, branch }) => {

  //========== Форматирование даты из БД в другие форматы =================
  const months = ["Січень", "Лютий", "Березень", "Квітень", "Травень", "Червень",
    "Липень", "Серпень", "Вересень", "Жовтень", "Листопад", "Грудень"]

  const addNull = (value) => {
    if (value < 10) {
      return `0${value}`
    } else {
      return value
    }
  }

  const startDate = new Date(invoice.date_invoice)
  const startDateNewFormat = `${addNull(startDate.getDate())}.${addNull(startDate.getMonth() + 1)}.${startDate.getFullYear()}`
  const startMonthName = months[startDate.getMonth()]
  const startYear = startDate.getFullYear()

  let endDate = new Date(invoice.date_invoice)
  endDate.setMonth(endDate.getMonth() + 1)
  const endDateNewFormat = `${addNull(endDate.getDate())}.${addNull(endDate.getMonth() + 1)}.${endDate.getFullYear()}`

  const startDateShort = `${startDate.getMonth() + 1}.${startDate.getFullYear()}`
  //========== /Форматирование даты из БД в другие форматы =================


  const details = invoice.services.map((elem, index) => {
    return (
      <div className={s.service} key={index}>
        <div className={s.serviceName}>
          <div>
            {elem.service_name}
          </div>
        </div>

        <div className={s.rightBlock}>
          <div className={s.comment}>
            {String(elem.sum_vsego).substr(0, 1) === '-' ? 'Переплата' : 'Заборгованість'}
          </div>
          <div className={s.value}>
            {elem.sum_vsego} <span className={s.ed}>грн.</span>
          </div>
        </div>
      </div >
    )
  })


  const tariffs = null

  // const tariffs = invoice.services.map((elem) => {
  //   return (
  //     <div key={elem.service_name}>

  //       {/* {(elem.tarif || elem.st_gkal) &&
  //         <div className={s.service1}>
  //           <div className={s.serviceName}>
  //             <div>
  //               {elem.service}:
  //             </div>
  //           </div>
  //           <div className={s.serviceValue}>
  //             <div>
  //               {elem.st_gkal || elem.tarif} грн/
  //               {
  //                 elem.service_id === 2
  //                   ?
  //                   <span>м<span className={s.index}>3</span></span>
  //                   : elem.service_id === 1
  //                     ? <span>Гкал</span>
  //                     : null
  //               }
  //             </div>
  //           </div>
  //         </div>
  //       } */}

  //       {elem.tariff &&
  //         <div className={s.service2}>
  //           <div className={s.serviceName}>{elem.service_name}</div>
  //           <div className={s.serviceValue}>{elem.tariff} {elem.service_dimension}</div>
  //         </div>
  //       }

  //     </div>
  //   )
  // })




  return (<div className={s.invoice}>

    <div className={s.payment}>
      <div className={s.title}>До сплати за {startMonthName}</div>

      <div className={s.contentPayment}>
        <div className={s.serviceContainer}>
          {details && details}
        </div>
        <div className={s.buttonContainer}>

          <a target="_blank" rel="noopener noreferrer" className={s.button} href="https://www.portmone.com.ua/r3/oplatit-communalnie-uslugi-kontsern-miski-teplovi-merezhi-zaporozhye">
            Сплатити онлайн
          </a>

          <div className={s.button} onClick={
            saverPdf.bind(null, { invoice, startDateNewFormat, startMonthName, endDateNewFormat, startDateShort, startYear, branch })
          }>Завантажити рахунок</div>

        </div>
      </div>
    </div>


    <div className={s.bottomBlock}>
      <div className={s.subscriberData}>
        <div className={s.subscriberDataTop}>

          <div className={s.field}>
            <div className={s.fieldName}>Особовий <br /> рахунок</div>
            <div className={s.value}>{invoice.contract[0].contract}</div>
          </div>

          <div className={s.field}>
            <div className={s.fieldName}>Опалювальна <br /> площа</div>
            <div className={s.value}> {invoice.contract[0].apartment_area}</div>
          </div>

          <div className={s.field}>
            <div className={s.fieldName}>Зареєстровано <br /> мешканців</div>
            <div className={s.value}>{invoice.contract[0].people}</div>
          </div>
        </div>

        <div className={s.field}>
          <div className={s.fieldName}>Власник</div>
          <div className={s.value}>{invoice.contract[0].fio}</div>
        </div>
        <div className={s.field}>
          <div className={s.fieldName}>Адреса</div>
          <div className={s.value}>{invoice.contract[0].adr}</div>
        </div>
      </div>

      <div className={s.tariff}>
        <div>
          Діючі тарифи
        </div>
        {tariffs}

      </div>
    </div>
  </div>)
}

export default Invoice