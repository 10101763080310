import {
  GET_INVOICES,
  PRELOADER,
  GET_CURRENT_DATE,
  //  GET_PAYMENTS, 
  // DEL_INVOICE,
  // DEL_PAYMENTS
} from '../actionTypes/invoicesTypes'
import { invoices } from '../../api/api'
// import { getNoticesAC } from '../actionCreators/userActionCreator'
// import { notificationOverpayment } from '../../constants'
// import Uniqid from 'uniqid'

// ------------------- Action Creators --------------------
// ------------ Get all invoices AC -------------
export const getInvoicesAC = (payload) => {
  return { type: GET_INVOICES, payload }
}

// ------------ Set preloader AC -------------
const preloaderAC = (payload) => {
  return { type: PRELOADER, payload }
}

const getCurrentDateAC = (payload) => {
  return { type: GET_CURRENT_DATE, payload }
}



// ------------------- Thunk Creators --------------------
// ---------------- Get Invoice TC--------------------
export const getInvoicesTC = (payload) => {
  // console.log("Start getInvoicesTC - 3")
  return (dispatch) => {
    dispatch(preloaderAC(true))
    return invoices
      .getInvoicesAPI(payload)
      .then((res) => {
        dispatch(preloaderAC(false))
        dispatch(getCurrentDateAC(res.data.current_date))
        // console.log(res.data.invoices)
        if (res.data.invoices.length > 0) {

          //============= Записываем в стор инвойсы ================
          dispatch(getInvoicesAC(res.data.invoices))

          // //===== Выбираем в инвойсах суммы с переплатой, и записываем их в стор для компонента Aside =====

          // // = Для этого: 1. создаем объект "arrContracts" из отсортированных по контракту инвойсов =
          // let arrContracts = {}
          // // let limit = false
        
          // res.data.invoices.forEach((inv) => {
          //   // console.log(inv.contract[0].contract)
          //   // console.log(inv.services)
          //   // Если объект пустой, то создаем новое свойство и заполняем его первым значением
          //   if (Object.keys(arrContracts).length === 0) {
          //     arrContracts[inv.contract[0].contract] = []
          //     arrContracts[inv.contract[0].contract].push(inv)
          //   } else {
          //     //Если объект не пустой, то перебираем его свойства и, если находим свойство равное 
          //     //текущему контракту, то дописываем в это свойство еще один объект
          //     let ind = true
          //     for (var key in arrContracts) {
          //       if (Number(key) === inv.contract[0].contract) {
          //         arrContracts[key].push(inv)
          //         ind = false
          //       }
          //     }
          //     //А, если не находим свойство равное текущему контракту,
          //     // то создаем новое свойство и заполняем его текущим значением
          //     if (ind === true) {
          //       arrContracts[inv.contract[0].contract] = []
          //       arrContracts[inv.contract[0].contract].push(inv)
          //     }
          //   }
          // })

          // // 2. = Создаем массив объектов-сообщений для каждого контракта =
          // let overpayments = []

          // // Берем один контракт 
          // for (var key in inv.services) {
          //   let stringOverpayments = ''
          //   let fio

          //   //... ищем в его массиве инвойсов отрицательные суммы и создаем строку переплат
          //   inv.services.forEach((inv) => {
          //     if (inv.sum_vsego.substr(0, 1) === "-") {
          //       fio = inv.fio
          //       stringOverpayments += `"${inv.service_name}" ${inv.sum_vsego} грн.; `
          //     }
          //   })

          //   // Если строка переплат создалась, то создаем объект и пушим его в массив для записи в стор.
          //   if (stringOverpayments) {
          //     const messageObj = {
          //       id: Uniqid(),
          //       type_message: "alert",
          //       title: `Увага!`,
          //       message: notificationOverpayment(fio, stringOverpayments)
          //     }
          //     overpayments.push(messageObj)
          //   }
          // }

          // // Записываем в стор сообщения о переплатах для всех контрактов
          // dispatch(getNoticesAC(overpayments))

        }
      })
      .catch((err) => {
        dispatch(preloaderAC(false))
        console.log('err:', err)
        // console.log('err.response.data:', err.response.data)
        // console.log('err.response.status:', err.response.status)
        // dispatch(getRegErrorMsgAC('msg'))
      })
  }
}
