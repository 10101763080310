import React from 'react'
import OneLocation from './OneLocation/OneLocation'
import s from '../PaymentArchive/PaymentArchive.module.scss'

const IndicationArchive = ({ sortLocationArchive, years }) => {

  const data = sortLocationArchive.map((oneLocation) => {
    return (
      <div key={oneLocation[0].location}>
        <div className={s.oneService}>
          <OneLocation oneLocation={oneLocation} years={years} />
        </div>
      </div>
    )
  })

  return (
    <div className={s.archiveComponent}>
      {data}
    </div>
  )
}


export default IndicationArchive