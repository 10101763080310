import React from "react"
import spinner from '../../../../images/spinner.svg'
import s from './Preloder.module.scss'
// import s_items from '../ContractItems.module.scss'

const Preloder = () => {
  return <div className={s.preloaderContainer}>
    <img src={spinner} alt="spinner" />
  </div>
}

export default Preloder