import React from 'react'
import s from './FormsControlLight.module.scss'

// export const TextArea = ({input, meta, ...props}) => {
//    return (
//       <div className={formsStyles.formControl + " " + (meta.touched && meta.error ? formsStyles.error : ' ')}>
//          <textarea {...input} {...props} />
//          {meta.touched && meta.error && <span>{meta.error}</span>}
//       </div>
//    )
// }

export const Input = ({ input, meta, label, ...props }) => {
  return (
    <div className={s.formControl + ' ' + (meta.touched && meta.error ? s.error : ' ')}>
      <div className={label === 'currentValue' ? s.fieldContainerCurrentValue : s.fieldContainer} >
        {/* <input {...input} {...props} className={label === 'currentValue' ? s.inputCurrentValue : s.inputAddContract} /> */}
        <input {...input} {...props} className={s.inputAddContract} />
        <div className={s.tip}>{props.placeholder}</div>
        {/* <img src={label == 'password' ? lock : label == 'email' ? mail : null} /> */}
      </div>
      <div className={meta.touched && meta.error ? s.isError : s.notError}>{meta.error} </div>
    </div>
  )
}

export const Checkbox = ({ input, meta, label, ...props }) => {
  return (
    <div className={s.formControl + ' ' + (meta.touched && meta.error ? s.error : ' ')}>
      <div className={s.checkboxContainer} >
        <input {...input} {...props} />
        <span> Згоден на обробку персональних даних</span>
        {/* <img src={label == 'password' ? lock : label == 'email' ? mail : null} /> */}
      </div>
      <div className={meta.touched && meta.error ? s.isError : s.notError}>{meta.error} </div>
    </div>
  )
}