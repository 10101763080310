import {
  GET_WATER_METERS, PRELOADER_SEND_BUTTON, SET_CURRENT_VALUE,
  PRELOADER_WMs, SET_SEND_NUMBER_MESSAGE
} from '../actionTypes/waterMetersTypes'

let initialState = {
  waterMeters: [],
  preloaderWMs: false,
  // sendNumberMessage: ''
}

const waterMetersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WATER_METERS: {
      return {
        ...state,
        waterMeters: [...action.payload]
      }
    }

    case SET_CURRENT_VALUE: {
      return {
        ...state,
        waterMeters: state.waterMeters.map((wm) => {
          if (wm.contract_id === action.payload.contract_id && wm.metering_device === action.payload.metering_device) {
            return {
              ...wm,
              metersdata: {
                ...wm.metersdata,
                current_value: action.payload.current_value
              },
            }
          } else return { ...wm }
        }),
      }
    }

    // case SET_SEND_NUMBER_MESSAGE: {
    //   return {
    //     ...state,
    //     sendNumberMessage: action.payload
    //   }
    // }


    case SET_SEND_NUMBER_MESSAGE: {
      return {
        ...state,
        waterMeters: state.waterMeters.map((wm) => {
          if (wm.contract_id === action.payload.contract_id && wm.metering_device === action.payload.metering_device) {
            return { ...wm, sendNumberMessage: action.payload.msg }
          } else return { ...wm }
        }),
      }
    }

    case PRELOADER_SEND_BUTTON: {
      return {
        ...state,
        waterMeters: state.waterMeters.map((wm) => {
          if (wm.contract_id === action.payload.contract_id && wm.metering_device === action.payload.metering_device) {
            return { ...wm, preloaderSendValue: action.payload.preloaderSendValue }
          } else return { ...wm }
        }),
      }
    }

    case PRELOADER_WMs: {
      return {
        ...state,
        preloaderWMs: action.payload
      }
    }

    default: return state
  }
}


export default waterMetersReducer