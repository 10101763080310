import React from "react"
import s from './Month.module.scss'

const monthDetailsArchive = ({ monthDetails, months, istMonthOpen, toggleMenuMode }) => {

  const dt = new Date(monthDetails[0].date_sum)
  const mounthString = dt.toLocaleString('uk', { month: 'long' });
  
  const data = monthDetails.map((pay, index) => {
    return (
      <tr className={s.trMonth} key={index}>
        <td className={s.tdService}>{pay.service_name}</td>
        <td className={s.tdMonthTotal}>{pay.sum_saldo_pred}</td>
        <td className={s.tdMonthTotal}>{pay.sum_nach}</td>
        <td className={s.tdMonthTotal}>{pay.sum_op}</td>
        <td className={s.tdMonthTotal}>{pay.sum_saldo}</td>
      </tr>
    )
  })

  return (
    <>
      <tr onClick={toggleMenuMode.bind(null, !istMonthOpen)} className={s.trTotal} >
        <td>{mounthString}</td>
        <td className={s.tdMonthTotal}>{monthDetails.map((elem) => parseFloat(elem.sum_saldo_pred)).reduce((total, amount) => total + amount).toFixed(2)}</td>
        <td className={s.tdMonthTotal}>{monthDetails.map((elem) => parseFloat(elem.sum_nach)).reduce((total, amount) => total + amount).toFixed(2)}</td>
        <td className={s.tdMonthTotal}>{monthDetails.map((elem) => parseFloat(elem.sum_op)).reduce((total, amount) => total + amount).toFixed(2)}</td>
        <td className={s.tdMonthTotal}>{monthDetails.map((elem) => parseFloat(elem.sum_saldo)).reduce((total, amount) => total + amount).toFixed(2)}</td>
      </tr>
      {(istMonthOpen || monthDetails[0].date_sum.substr(5, 2) === months[0]) && data}
    </>
  )
}

export default monthDetailsArchive