import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  passwdResetTC, getSuccessResetMsgAC, getFailureMsgAC, getSuccessMsgAC, setLogEmailAC
} from '../../store/actionCreators/userActionCreator'
import { activationLetter } from '../../constants'
import Preloder from "../MainPage/Contract/Preloader/Preloader"
// import s from './LogReg.module.scss'
import PasswdReset from './PasswdReset'
import ModalWindow from '../ModalWindow/ModalWindow'



const PasswdResetContainer = () => {
  const dispatch = useDispatch()
  const successResetMsg = useSelector((state) => state.userPage.successResetMsg)
  const failureResetMsg = useSelector((state) => state.userPage.failureResetMsg)
  const failureMsg = useSelector((state) => state.userPage.failureMsg)
  const successMsg = useSelector((state) => state.userPage.successMsg)
  const logEmail = useSelector((state) => state.userPage.logEmail)

  const preloader = useSelector((state) => state.userPage.preloader)

  //Удаляем пробелы введенные пользователем
  const pressKeyHandler = (value) => value.replace(/\s/g, '')

  const onSubmit = (formData) => {
    const msg = `Ваш пароль був успішно змінений`
    dispatch(passwdResetTC({ newPasswd: formData, msg }))
  }

  const [iconVisibility, setIconVisibility] = useState(false)
  const displayIcon = () => {
    setIconVisibility(!iconVisibility)
  }

  const clearMessages = () => {
    dispatch(getSuccessResetMsgAC(''))
  }


  //======================================== Modal Window ============================
  const [modalIsOpen, setIsOpen] = useState(false)

  const closeModal = () => {
    dispatch(getSuccessMsgAC(''))
    dispatch(getFailureMsgAC(''))
    dispatch(setLogEmailAC(''))
    dispatch(getSuccessResetMsgAC(''))
    setIsOpen(false)
  }

  console.log("PasswdResetContainer", failureMsg)
  let msg

  if (successResetMsg) msg = successResetMsg
  if (failureResetMsg) msg = failureResetMsg
  if (successMsg) msg = activationLetter(successMsg)
  if (failureMsg) msg = failureMsg

  //======================================= / Modal Window ==========================

  useEffect(() => {
    if (msg) setIsOpen(true)
  }, [msg])


  if (preloader) return <Preloder />
  return (<>
    <PasswdReset
      onSubmit={onSubmit}
      // successResetMsg={successResetMsg}
      // failureResetMsg={failureResetMsg}
      displayIcon={displayIcon}
      iconVisibility={iconVisibility}
      clearMessages={clearMessages}
      pressKeyHandler={pressKeyHandler}
    />
    <ModalWindow modalIsOpen={modalIsOpen} closeModal={closeModal} msg={msg} logEmail={logEmail} />
  </>
  )
}

export default PasswdResetContainer
