export const required = (value) => {
  // console.log("required", value)
  if (value) {
    return undefined
  } else {
    return "Це поле потрібно заповнити обов'язково"
  }
}

export const email = (value) => {
  if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'Введена не коректна адреса'
  }
  return undefined
}

export const emailReg = (value) => {
  if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'Введена не коректна адреса'
  }
  if (
    value.indexOf("@mail.ru") !== -1
    || value.indexOf("@inbox.ru") !== -1
    || value.indexOf("@list.ru") !== -1
    || value.indexOf("@bk.ru") !== -1
    || value.indexOf("@internet.ru") !== -1
    || value.indexOf("@yandex") !== -1
  ) return 'Вказаний поштовий сервіс - не обслуговується'
  return undefined
}

export const password = (value) => {
  if (value && !/^([a-zA-Z0-9]){8,20}$/i.test(value)) {
    return 'Тільки латинські букви і цифри. 8-12 символів'
  }
  return undefined
}

export const matchingPasswd = (matchName) => (value, allValues, props) =>
  value !== allValues[matchName] ? `Паролі не співпадають` : undefined


export const matchingPasswd2 = (value, allValues, props) => {
  return value !== allValues.password ? `Паролі не співпадають` : undefined
}

export const valueСomparatorCreator = (prevValue) => (value, allValues, props) => {

  // let newValue
  // if (value && typeof (value) === 'string') {
  //   newValue = Number(value.replace(',', '.'))
  // }

  if (value && prevValue && value < prevValue) {
    return `Поточне значення не повинно бути менше попереднього`
  }
  return undefined

  // value < allValues[prevValue] ? `Поточне значення не повинно бути менше попереднього` : undefined
}

const phoneCodes = ["039", "067", "068", "096", "097", "098", "050", "066", "095", "099", "063", "073", "093", "089", "094", "091", "092"]

export const phone = (value) => {
  let ind = false
  phoneCodes.forEach((code) => {
    if (value.substr(0, 3) === code) ind = true
  })

  return ((value && !/^\d{10}$/.test(value)) || (value && !ind)) ? 'Номер телефону не дійсний' : undefined
}

export const number10 = (value) =>
  value && !/^\d{3,10}$/.test(value) ? 'Це поле повинно містити від 3-х до 10-ти цифр' : undefined

export const numberValue = (value) => {
  if (value && !/^([0-9]+[.,]?[0-9]*)$/.test(value)) {
    return 'Це поле повинно містити тільки цифри і кому'
  }
  return undefined
}