import React from 'react'
import s from './constants.module.scss'

export const notificationVerif = (sn) => {
  let text = ''
  if (sn) {
    text = ``
  } else {
    text = ``
  }
  return text
}

export const notificationNorma = (sn) => {
  let text = ''
  if (sn) {
    text = ``
  } else {
    text = ``
  }
  return text
}

export const notificationOverpayment = (fio, stringOverpayments) => {
  const text = `Шановний абоненте ${fio}, у Вас переплата за послугою:
  ${stringOverpayments}
  Цю переплату можливо зарахувати на сплату іншої послуги, 
  яка нараховується надіславши заяву на ел.пошту 
  zvernennya@teploseti.zp.ua або за адресою Філіалу.`
  return text
}

export const activationLetter = (email) => {
  const text = <div>
    <div className={s.line}>На електрону пошту {email} було відправлено листа для підтвердження.</div>
    <div className={s.line}>Якщо Ви не бачите листа, перевірте папку "Спам".</div>
    <div className={s.line}>Після підтвердження ви отримаєте можливість увійти в особистий кабінет</div>
  </div>
  return text
}


export const phoneVerif = ``//Для проходження державної повірки приладів обліку Ви можете звернутися за тел.
export const limitation25 = 'Показання квартирних приладів обліку гарячої води за поточний місяць приймаються до 25-го числа. Показання передані після 25-го числа будуть враховані на наступний місяць.'
export const more200cubic = 'Будь ласка, скористайтесь телефоном для передачі показань, якщо спожитий обсяг води 200 м.куб. і вище.'
export const delContractMsg = 'Ви дійсно хочете видалити особовий рахунок з вашого особистого кабінету?'
export const confirmationSendNumb = `Ви впевнені, що хочете передати показання: `
export const limitContractsMsg = `Неможливо додати більше п'яти особових рахунків до вашого кабінету.`
export const paymentDetails = 'ЄДРПОУ 32121458, \nМФО 322313, \nр/р UA853223130000026007000047922 у Філії АТ «Укрексімбанк» у м. Києві'
