import React from "react"
import IndicationArchive from './IndicationArchive'
import { useSelector } from 'react-redux'
import Preloader from '../Preloader/Preloader'
import NoData from '../NoData/NoData'

const IndicationArchiveContainer = ({ indicArchive }) => {



  const preloader = useSelector((state) => state.indicationArchivePage.preloader)
  let sortLocationArchive = {}
  let years = []

  if (preloader) return <Preloader />
  if (!indicArchive || !indicArchive.length) return <NoData />

  // Перебирая архив заполняем years годами из архива и заполняем sortLocationArchive тем-же архивом, но сортированным по сервисам
  indicArchive.forEach((item) => {
    if (!years.includes(item.date_editing.substr(0, 4))) years.push(item.date_editing.substr(0, 4))

    let location = item.location
    if (sortLocationArchive[location]) {
      sortLocationArchive[location].push(item)
    } else {
      sortLocationArchive[location] = [item]
    }
  })

  // console.log("IndicationArchive до: ", sortLocationArchive)

  sortLocationArchive = Object.values(sortLocationArchive)    // Делаем из объекта массив
  // console.log("IndicationArchive после: ", sortLocationArchive)

  years.sort((a, b) => b - a)  // Сортируем список годов по убыванию



  return <IndicationArchive
    // contractId={contractId}
    sortLocationArchive={sortLocationArchive}
    // indicArchive={indicArchive}
    years={years} />
}

export default IndicationArchiveContainer