import React, { useState } from 'react'
import OneYearArchiveContainer from '../OneYearArchive/OneYearArchiveContainer'
import s from './OneService.module.scss'

const OneServiceContainer = ({ oneService }) => {

  let years = []
  oneService.forEach((item) => {
    if (!years.includes(item.date_sum.substr(0, 4))) years.push(item.date_sum.substr(0, 4))
  })
  years.sort((a, b) => b - a)  // Сортируем список годов по убыванию

  const [selectYear, setYear] = useState(years[0])

  const oneYear = oneService.filter((elem) => {
    return elem.date_sum.substr(0, 4) === selectYear
  })

  // Сортируем месяцы в архиве по возрастанию (убыванию)
  oneYear.sort((a, b) => {
    return a.date_sum.substr(5, 2) - b.date_sum.substr(5, 2)
  })

  const clickYearHandler = (year) => {
    setYear(year)
  }

  const yearList = years.map((year) => {
    return (
      <div key={year}
        className={year === selectYear ? s.activeYear : s.passiveYear}
        onClick={clickYearHandler.bind(null, year)}
      >
        {year}
      </div>
    )
  })
  return <>
    <div className={s.title2}>
      <div className={s.serviceName}>{oneService[0].service_name}</div>
      <div className={s.yearList}>{yearList}</div>
    </div>
    <OneYearArchiveContainer oneYear={oneYear} />
  </>
}

export default OneServiceContainer
