// import React from "react"
import React, { useState } from 'react'
import OneServiceContainer from "./OneService/OneServiceContainer"
import MonthContainer from "./MonthArchive/MonthContainer"
import s from './PaymentArchive.module.scss'

const PaymentArchive = ({archive, sortServicesArchive, years}) => {

  const [selectYear, setYear] = useState(years[0])

  const oneYear = archive.filter((elem) => {
    return elem.date_sum.substr(0, 4) === selectYear
  })

  // Сортируем месяцы в архиве по возрастанию (убыванию)
  oneYear.sort((a, b) => {
    // return b.date_sum.substr(5, 2) - a.date_sum.substr(5, 2)
    return a.date_sum.substr(5, 2) - b.date_sum.substr(5, 2)
  })

  const clickYearHandler = (year) => {
    setYear(year)
  }

  const yearList = years.map((year) => {
    // console.log("key: ", year)
    return (
      <div key={year}
        className={year === selectYear ? s.activeYear : s.passiveYear}
        onClick={clickYearHandler.bind(null, year)}
      >
        {year}
      </div>
    )
  })

  let sortMonthArchive = {}

  oneYear.forEach((item) => {

    let dateMonth = item.date_sum
    if (sortMonthArchive[dateMonth]) {
      sortMonthArchive[dateMonth].push(item)
    } else {
      sortMonthArchive[dateMonth] = [item]
    }

  })
  
  sortMonthArchive = Object.values(sortMonthArchive)
  let monthsList =[]
  oneYear.forEach((item) => {
    if (!monthsList.includes(item.date_sum.substr(5, 2))) monthsList.push(item.date_sum.substr(5, 2))
  })

  const dataMonths = sortMonthArchive.map((monthDetails, index) => {
    // console.log("key: ", monthDetails)
    return (
      <MonthContainer key={index} monthDetails={monthDetails} months={monthsList} />
    )
  })
  
  const dataServices = sortServicesArchive.map((oneService) => {
    return (
      <div key={oneService[0].service_name}>
        <div className={s.oneService}>
          <OneServiceContainer oneService={oneService} years={years} />
        </div>
      </div>
    )
  })

  // Вигляд архіву
  let viewArchive = ['по місяцях','по послугам']
  const [selectViewArchive, setViewArchive] = useState(viewArchive[0])

  const clickViewArchiveHandler = (viewArchive) => {
    setViewArchive(viewArchive)
  }

  const viewArchiveList = viewArchive.map((viewArchive) => {
    // console.log("key: ", year)
    return (
      <div key={viewArchive}
        className={viewArchive === selectViewArchive ? s.activeYear : s.passiveYear}
        onClick={clickViewArchiveHandler.bind(null, viewArchive)}
      >
        {viewArchive}
      </div>
    )
  })

  return (
    <div className={s.archiveComponent}>
      <div className={s.viewArchive}>Вигляд архіву: {viewArchiveList}</div>
      <div>{selectViewArchive === viewArchive[0] ?
      <>
        <div className={s.yearListComponent}>
          <div className={s.yearList}>{yearList}</div>
        </div> 
        <table className={s.oneYearTableMonth}>
          <thead>
            <tr>
              <th>Звітний місяць</th>
              <th>На поча&shy;ток</th>
              <th>Нарахо&shy;ва&shy;но</th>
              <th>Спла&shy;че&shy;но</th>
              <th>Сума до сплати</th>
            </tr>
          </thead>
          <tbody>
            {dataMonths}
          </tbody>
        </table> 
      </>
      : dataServices}</div>
      </div>
  )
}

export default PaymentArchive
