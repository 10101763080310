import React from 'react'
import { useSelector } from 'react-redux'
import Aside from './Aside'
import Preloader from '../Contract/Preloader/Preloader'

const AsideContainer = () => {
  const notices = useSelector((state) => state.userPage.notices)
  const preloader = useSelector((state) => state.userPage.preloader)

  if (preloader) {
    return <Preloader />
  }

  if (notices.length) {
    return <Aside notices={notices} preloader={preloader} />
  } else {
    return null
  }

}

export default AsideContainer
