import React, { useEffect } from 'react'
import { Field, reduxForm } from 'redux-form'
import { required, numberValue } from '../../../../../validators/validators'
import { Input } from '../../../../FormsControlLight/FormsControlLight'
import { dateConverter } from '../../../../../utils.js'
import spinner from '../../../../../images/spinner2.svg'
import s from './CurrentValueForm.module.scss'

const CurrentValueForm = ({
  handleSubmit, value_m3, pressKeyHandler,
  preloaderSendValue, prev_date,
  prev_value, valueСomparator, currentValueInit, initialize
}) => {

  // !!!!!!!!!!!! изменяем значение по умолчанию поля current_value через функцию initialize(), для тех случаев, когда один счетчик выводится больше 1-го раза 
  useEffect(() => {
    initialize({ current_value: currentValueInit })
  }, [currentValueInit])

  return (
    <form action="" className={s.currentValueForm} onSubmit={handleSubmit}>

      <div className={s.newForm}>

        <label>Введіть поточні показання, <span>м<span className={s.cub}>3</span></span></label>
        <div className={s.currentValueBlock}>
          <Field
            className={s.item_indication}
            name="current_value"
            // placeholder=" Введіть покази"
            component={Input}
            type="number"
            step="0.001"
            // min={prev_value}
            validate={[required, numberValue, valueСomparator]}
            onChange={(e) => { pressKeyHandler(e.target.value) }}
            label="currentValue"
            autoComplete="off"
          // normalize={inputHandler} // Нужно изменить type="number"
          />
        </div>

        {prev_value
          ?
          <label className={s.long} >Попередні показання станом на
            <span className={s.nowrap}>{prev_date && ` ${dateConverter(prev_date)}`}, <span></span>
              <span>
                м
                <span className={s.cub}>3</span>
              </span>
            </span>
          </label>
          : null
        }
        {prev_value
          ? <div><input type="text" disabled value={prev_value} /><div className={s.space}></div></div>
          : null
        }

        <label>Спожитий обсяг води, <span>м<span className={s.cub}>3</span></span></label>

        <div>
          <input className={value_m3 >= 100 ? s.value_m3_red : s.value_m3} type="text" disabled value={value_m3} />
          <div className={s.space}></div>
        </div>

        <div></div>
        <div className={s.button}>
          {preloaderSendValue
            ? <img src={spinner} alt="spinner" />
            : <button type="submit">Надати</button>
          }
        </div>
      </div >
    </form >
  )
}

const CurrentValueReduxForm = reduxForm({})(CurrentValueForm)
export default CurrentValueReduxForm