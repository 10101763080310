import React from 'react'
import Footer from './Footer'
import { useSelector } from 'react-redux'

const FooterContainer = () => {
  const userEmail = useSelector((state) => state.userPage.userEmail)
  return <Footer userEmail={userEmail} />
}

export default FooterContainer
