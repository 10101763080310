import {
  GET_INDICATION_ARCHIVE, PRELODER_INDICATION_ARCHIVE, DEL_INDICATION_ARCHIVE
} from '../actionTypes/indicationArchiveTypes'

const initialState = {
  indicationArchive: {},
  preloader: false
}

const indicationArchiveReducer = (state = initialState, action) => {

  // console.log("indicationArchiveReducer", action.payload)

  switch (action.type) {

    case GET_INDICATION_ARCHIVE: {
      return {
        ...state,
        indicationArchive: {
          ...state.indicationArchive,
          [`${action.payload.contractId}`]: [...action.payload.meterdata_archive]
        }
      }
    }

    case DEL_INDICATION_ARCHIVE: {
      const newIndicationArchive = { ...state.indicationArchive }
      delete newIndicationArchive[`${action.payload}`]
      return {
        ...state,
        indicationArchive: { ...newIndicationArchive }
      }
    }

    case PRELODER_INDICATION_ARCHIVE: {
      return {
        ...state,
        preloader: action.payload
      }
    }

    default: return state
  }
}

export default indicationArchiveReducer