import React from 'react'
import Header from './Header'
import { useDispatch, useSelector } from 'react-redux'
import { authorizationAC, resetReduxAC } from "../../store/actionCreators/userActionCreator"

const HeaderContainer = () => {

  const dispatch = useDispatch()
  const userEmail = useSelector((state) => state.userPage.userEmail)

  const exitHandler = () => {

    //Удаляем созданные приложением куки
    document.cookie = "userToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT;"
    document.cookie = "userEmail=; expires=Thu, 01 Jan 1970 00:00:00 GMT;"

    // Удаляем все куки сайта
    function deleteAllCookies() {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
        document.cookie = name + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }
    }
    deleteAllCookies()
    dispatch(resetReduxAC())
    dispatch(authorizationAC({ userToken: "", userEmail: "" }))
  }

  return <Header userEmail={userEmail} exitHandler={exitHandler} />
}

export default HeaderContainer
