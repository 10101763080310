import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CurrentValueForm from './CurrentValueForm'
import {
  setCurrentValueTC, getWaterMetersTC, setSendCurrentMessageAC
} from '../../../../../store/actionCreators/waterMetersActionCreator'
import ModalWindow from '../../../../ModalWindow/ModalWindow'
import { valueСomparatorCreator } from '../../../../../validators/validators'
import ModalVerification from '../../ModalVerification/ModalVerification'
import { more200cubic } from '../../../../../constants'

const CurrentValueFormContainer = ({
  metersdata, preloaderSendValue, sendNumberMessage,
  contractId, metering_device
}) => {

  const dispatch = useDispatch()
  const userToken = useSelector((state) => state.userPage.userToken)

  //======================================== Modal Del Contract ============================
  //Открытие-закрытие модального окна
  const [modalIsOpen, setIsOpen] = useState(false)

  const closeModal = () => {
    dispatch(getWaterMetersTC(userToken))
    dispatch(setSendCurrentMessageAC(''))
  }

  // Когда меняется значение setCurrentValueMsg - выводить модальное окно с этим сообщением
  useEffect(() => {
    if (sendNumberMessage) setIsOpen(true)
    else setIsOpen(false)
  }, [sendNumberMessage])
  //=========================================== / Modal Del Contract ======================


  //======================================== Modal Send Number ============================
  //Открытие-закрытие модального окна при больших данных
  const [modalSendNumberIsOpen, setModalSendNumberIsOpen] = useState(false)
  // Обнуляем modalSendNumberIsOpen, чтобы в useEffect закрылось модальное окно
  const closeModalSendNumber = () => { setDataModalVerification({}) }

  // Установка сообщения для модального окна
  const [dataModalVerification, setDataModalVerification] = useState({})

  //======================================== / Modal Send Number =========================

  if (!metersdata) { metersdata = {} }

  // Вычисление разницы показаний с округлением до 3-х знаков после запятой
  const calculations = (prev_value = 0, current_value) => {
    // if (prev_value === null || !current_value) return ''
    if (!current_value) return ''
    else return Number(Math.round((current_value - prev_value) + 'e' + 3) + 'e-' + 3)
  }

  //==================== Устанавливаем  значение value_m3 для 3-х ситуаций: ====================
  //1. Стартовое значение при первоначальной отрисовке компонента
  //2. При вводе значения current_value
  //3. В случае, если значение в стейте изменилось после запроса на сервер

  // 1. Установка стартового количества потребленной воды для отображения на экране.
  const [value_m3, setValue_m3] = useState(
    calculations(metersdata.prev_value, metersdata.current_value)
  )

  // 2. Установка количества потребленной воды для отображения на экране при вводе пользователем
  const pressKeyHandler = (value) => {
    // setValue_m3(
    //   (!metersdata.prev_value || !value)
    //     ? null
    //     : round((Number(value.replace(',', '.')) - metersdata.prev_value), 3)
    // )
    setValue_m3(
      calculations(metersdata.prev_value, value)
    )
  }

  // 3. Установка количества потребленной воды, если в запросе пришли новые значения current_value и prev_value,
  // для тех случаев, когда один счетчик выводится больше 1-го раза на экране (в разных контрактах)
  useEffect(() => {
    setValue_m3(calculations(metersdata.prev_value, metersdata.current_value))
  }, [metersdata.current_value, metersdata.prev_value])
  // =================================================================================================

  // Проверка потребленного количества воды, если больше 99 м.куб. то вывести предупреждение
  const valueCheck = (formData) => {
    if (value_m3 >= 200) {
      dispatch(setSendCurrentMessageAC({
        contract_id: contractId,
        metering_device,
        msg: more200cubic
      }))
    } else if (value_m3 >= 100) setDataModalVerification({ ...formData })
    else setCurentValueHandler(formData)
  }

  // Формирование объекта и отправка запроса.
  const setCurentValueHandler = (formData) => {
    // setModalSendNumberIsOpen(false)    
    const payload = {
      data: {
        contract: contractId,
        metering_device,
        prev_value: metersdata.prev_value,
        prev_date: metersdata.prev_date,
        current_value: Number(formData.current_value),
        date_check: formData.date_check,
        value_m3
      },
      userToken: userToken
    }
    dispatch(setCurrentValueTC(payload))
    closeModalSendNumber()
  }

  const valueСomparator = valueСomparatorCreator(metersdata.prev_value)

  // Когда меняется значение dataModalVerification - выводить модальное окно для подтверждения
  useEffect(() => {
    if (Object.keys(dataModalVerification).length !== 0) setModalSendNumberIsOpen(true)
    else setModalSendNumberIsOpen(false)
  }, [dataModalVerification])




  return (
    <>
      <CurrentValueForm
        form={`${String(metering_device)}${String(contractId)}`}
        initialValues={{ current_value: metersdata.current_value }}
        date_check={metersdata.date_check}
        // setCurrentValueMsg={setCurrentValueMsg}
        preloaderSendValue={preloaderSendValue}
        onSubmit={valueCheck}
        value_m3={value_m3}
        pressKeyHandler={pressKeyHandler}
        prev_value={metersdata.prev_value}
        prev_date={metersdata.prev_date}
        valueСomparator={valueСomparator}
        currentValueInit={metersdata.current_value}
      // inputHandler={inputHandler}
      />

      <ModalVerification
        modalIsOpen={modalSendNumberIsOpen} // Переменная по которой окно открывается или закрывается
        closeModal={closeModalSendNumber}   // Функция, которая меняет значение переменной modalSendNumberIsOpen на false
        operation='sendNumber'              // Значение, которое формирует соответствующее модальное окно
        setCurentValueHandler={setCurentValueHandler} // Функция, которая сформирует объект и отправит показания
        dataModalVerification={dataModalVerification} // Данные для отправки показаний функцией setCurentValueHandler
      />

      <ModalWindow modalIsOpen={modalIsOpen} closeModal={closeModal} msg={sendNumberMessage} />
    </>
  )
}

export default CurrentValueFormContainer