import {
  GET_WATER_METERS, PRELOADER_SEND_BUTTON, SET_CURRENT_VALUE,
  PRELOADER_WMs, SET_SEND_NUMBER_MESSAGE
} from '../actionTypes/waterMetersTypes'
import { waterMeters } from '../../api/api'
import { showNotification_verif } from '../../utils'
import { notificationVerif, notificationNorma, phoneVerif, limitation25 } from '../../constants'
import { getNoticesAC } from './userActionCreator'
import { getIndicationArchiveTC } from './indicationArchiveActionCreator'
import Uniqid from 'uniqid'

// --------------- Action Creators ----------------
// ------------ Get all WaterMeter AC -------------
export const getWaterMetersAC = (payload) => {
  return { type: GET_WATER_METERS, payload }
}

//----------------Set current value AC------------------
export const setCurrentValueAC = (payload) => {
  return { type: SET_CURRENT_VALUE, payload }
}

//----------------Set preloader button AC------------------
export const preloaderSendButtonAC = (payload) => {
  return { type: PRELOADER_SEND_BUTTON, payload }
}

//----------------Set send current message AC------------------
export const setSendCurrentMessageAC = (payload) => {
  return { type: SET_SEND_NUMBER_MESSAGE, payload }
}

//----------------Set preloader woter meters AC------------------
export const preloaderWMsAC = (payload) => {
  return { type: PRELOADER_WMs, payload }
}



// --------------- Thunk Creators ----------------
// ------------ Get all WaterMeter TC -------------
export const getWaterMetersTC = (payload) => {
  // console.log("Start getWaterMetersTC - 4")
  return (dispatch, getState) => {
    dispatch(preloaderWMsAC(true))
    waterMeters.getWaterMetersAPI(payload)
      .then((res) => {
        dispatch(preloaderWMsAC(false))
        // console.log("ok: ", res)
        dispatch(getWaterMetersAC(res.data.metering_devices))


        let notifications = []

        // ======= Формируем сообщение об ограничении подачи показаний 25-м числом ======
        // console.log("res.data.metering_devices.length", res.data.metering_devices.length)
        const arrNotices = getState().userPage.notices
        let noticeUpdate = false
        if (res.data.metering_devices.length) {
          arrNotices.forEach((notice) => {
            if (notice.message === limitation25) {
              noticeUpdate = true
            }  
          })
          if (!noticeUpdate) {
             const messageObj2 = {
              id: Uniqid(),
              type_message: "message",
              title: `Щодо надання показань`,
              message: limitation25
            }
            notifications.push(messageObj2)
          }
        }

        // ================= Формируем и пишем в стор уведомления о поверке счетчиков ==================
        const arrContracts = getState().contractsPage.contracts
        const current_date = getState().invoicesPage.current_date

        // Функция перебирает contractsPage.contracts и возвращает номера телефонов для поверки 
        const getPhone_verify = (id) => {
          let phone
          arrContracts.forEach((contr) => {
            if (contr.contract === id) {
              phone = contr.branch.phone_verify
            }
          })
          return phone
        }

        // Перебираем счетчики, находим пропустившие поверку и формируем объекты для уведомлений в Aside
        res.data.metering_devices.forEach((wm) => {

          const messageObj = {
            // id: Uniqid(),
            type_message: "alert",
            title: ``
          }
          // console.log("current_date", current_date)
          const verificationCheck = showNotification_verif(wm.date_verification, false, current_date)  // вставить дату с сервера

          if (verificationCheck === 'warningVerif') {
            messageObj.id = Uniqid()
            // messageObj.message = <span>{notificationVerif(wm.serial_number)} <br /> <b>{phoneVerif}</b></span>   // messageObj.message должен быть массивом, а не объектом
            messageObj.message = `${notificationVerif(wm.serial_number)} ${phoneVerif}`
            messageObj.phone_verify = getPhone_verify(wm.contract_id)
            notifications.push(messageObj)
          }

          if (verificationCheck === 'warningNorma') {
            messageObj.id = Uniqid()
            // messageObj.message = <span>{notificationNorma(wm.serial_number)} <br /> <b>{phoneVerif}</b></span>
            messageObj.message = `${notificationNorma(wm.serial_number)} ${phoneVerif}`
            messageObj.phone_verify = getPhone_verify(wm.contract_id)
            notifications.push(messageObj)
          }
        })
        // Записываем в стор сообщения сообщение об ограничении подачи показаний 25-м числом и о поверке счетчиков
        dispatch(getNoticesAC(notifications))
      })
      .catch((err) => {
        dispatch(preloaderWMsAC(false))
        console.log("err: ", err)
      })
  }
}

// ---------------- Set current value TC--------------------
export const setCurrentValueTC = (payload) => {
  return (dispatch) => {
    dispatch(preloaderSendButtonAC({   // запускаем спиннер
      contract_id: payload.data.contract,
      metering_device: payload.data.metering_device,
      preloaderSendValue: true
    }))
    waterMeters.setCurrentValueAPI(payload)
      .then((res) => {

        return Promise.all([
          dispatch(preloaderSendButtonAC({   // останавливаем спиннер
            contract_id: payload.data.contract,
            metering_device: payload.data.metering_device,
            preloaderSendValue: false
          })),
          dispatch(setSendCurrentMessageAC({         // записываем сообщение от сервера об успехе
            contract_id: payload.data.contract,
            metering_device: payload.data.metering_device,
            msg: res.data.message
          })),
          // dispatch(setSendCurrentMessageAC(res.data.message))
          dispatch(setCurrentValueAC({
            contract_id: payload.data.contract,
            metering_device: payload.data.metering_device,
            current_value: payload.data.current_value
          }))
        ])

      })
      .then(() => {
        // console.log("setCurrentValueTC", payload.data.contract, payload.userToken)
        dispatch(getIndicationArchiveTC({ contractId: payload.data.contract, userToken: payload.userToken }))
      })
      .catch((err) => {
        dispatch(preloaderSendButtonAC({    // останавливаем спиннер
          contract_id: payload.data.contract,
          metering_device: payload.data.metering_device,
          preloaderSendValue: false
        }))
        console.log('err:', err)
        // console.log('err.response.data:', err.response.data)
        // console.log('err.response.status:', err.response.status)

        dispatch(setSendCurrentMessageAC({         // записываем сообщение от сервера об успехе
          contract_id: payload.data.contract,
          metering_device: payload.data.metering_device,
          msg: err.response.data.message
        }))
      })
  }
}