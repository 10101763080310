import React from 'react'
import s from './Contract.module.scss'
import SendNumberContainer from './SendNumber/SendNumberContainer'
import InvoiceContainer from './Invoice/InvoiceContainer'
import PaymentArchiveContainer from './PaymentArchive/PaymentArchiveContainer'
import IndicationArchiveContainer from './IndicationArchive/IndicationArchiveContainer'

const Contract = ({
  contractId, invoice, namePage, archive, fio, openModal, isMenuOpen, toggleMenuMode,
  namePageHandler, theseWaterMeters, branch, indicArchive,
}) => {
  return (
    <div className={s.contractContainer}>

      {/* Кнопка - бургер */}
      <div
        className={s.burgerButton + ' ' + (isMenuOpen ? s.burgerButtonActive : ' ')}
        aria-label="Головне меню"
        onClick={toggleMenuMode.bind(null, !isMenuOpen)}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      {/* Заголовок */}
      <div className={s.title_contract}>
        <b>{fio}, </b> <span>о/р: <b>{contractId}</b></span>
      </div>

      <div className={s.contract_data}>

        {/* Меню */}
        <div className={isMenuOpen ? s.leftBlock : s.hideLeftBlock}>

          <div className={s.menu}>
            <div
              className={namePage === 'invoice' ? s.active_menu_item : s.menu_item}
              onClick={namePageHandler.bind(null, "invoice")} >
              Нарахування
            </div>
            <div className={s.line}></div>

            {/*Кнопка передать показания - отключена до тех пор, пока не будет горячей воды*/}
            {/*<div
              className={namePage === 'sendNumber' ? s.active_menu_item : s.menu_item}
              onClick={namePageHandler.bind(null, "sendNumber", contractId)}>
              Передати показання
            </div>
            <div className={s.line}></div>*/}

            <div
              className={namePage === 'paymentArchive' ? `${s.active_menu_item} ${s.notShow}` : `${s.menu_item} ${s.notShow}`}
              onClick={namePageHandler.bind(null, "paymentArchive", contractId)} >
              Архів платежів
            </div>
            <div className={s.line}></div>

            <div
              className={namePage === 'indicationArchive' ? s.active_menu_item : s.menu_item}
              onClick={namePageHandler.bind(null, "indicationArchive", contractId)} >
              Архів показань
            </div>

            {/* <div className={s.line}></div>
            <div
              className={namePage === 'generalMeter' ? s.active_menu_item : s.menu_item}
              onClick={namePageHandler.bind(null, "generalMeter", contractId)} >
              Будинковий <br /> лічильник
            </div>*/}
          </div>

          <div className={s.delButton} onClick={openModal}>
            Видалити рахунок
          </div>
        </div>

        {namePage === 'invoice' && <InvoiceContainer invoice={invoice} branch={branch} />}
        {namePage === 'sendNumber' && <SendNumberContainer theseWaterMeters={theseWaterMeters} contractId={contractId} />}
        {/* {namePage === 'generalMeter' && <GeneralMeterContainer invoice={invoice} />} */}
        {namePage === 'paymentArchive' && <PaymentArchiveContainer archive={archive} />}
        {namePage === 'indicationArchive' && <IndicationArchiveContainer indicArchive={indicArchive} />}

      </div>
    </div >
  )
}

export default Contract
