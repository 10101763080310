// Функция преобразует формат даты из БД в человеческий.
export const dateConverter = (date) => {
  if (!date) return '-'
  const parts = date.split('-');
  return `${parts[2]}.${parts[1]}.${parts[0]}`
}

// ============ Функция определяет нужно-ли выводить уведомление пользователю о необходимости поверки счетчика ==================
// Ф-ция должна получить дату прошлой поверки, текущую дату и на норме абонент или нет.
export const showNotification_verif = (verifDateFromDB, norma, currentDate) => {
  // если сегодняшняя дата позднее чем (нало квартала - 1 месяц) и раньше чем конец квартала
  // то возвращаем "warningVerif"
  // если сегодняшняя дата позднее чем конец квартала,
  // то возвращаем "warningNorma"
  // если сегодняшняя дата меньше даты начала отображения уведомления, - то возвращаем "null" 

 // if (norma) {      // Если пользователь на норме потребления, -  возвращаем "null", поверять счетчик не нужно
  //  console.log("return", "null")
  //  return "null"
  //}

  //const today = new Date(currentDate)                      //Создаем объект дата сегодняшнего дня.
  //const verifDate = new Date(verifDateFromDB)               //Создаем объект дата на основе поля из БД      
 // verifDate.setFullYear(verifDate.getFullYear() + 4)        // прибавляем 4 года      
  //const verifQuarter = Math.floor((verifDate.getMonth() + 3) / 3)    // Определяем квартал для поверки счетчика

  //let currentQuarter
 // switch (verifQuarter) {                                    // определяем массив дат для данного квартала
  //  case 1: currentQuarter = [new Date(verifDate.getFullYear(), 0, 1), new Date(verifDate.getFullYear(), 2, 31),]
   //   break;
  //  case 2: currentQuarter = [new Date(verifDate.getFullYear(), 3, 1), new Date(verifDate.getFullYear(), 5, 30),]
   //   break;
    //case 3: currentQuarter = [new Date(verifDate.getFullYear(), 6, 1), new Date(verifDate.getFullYear(), 8, 30),]
     /* break;
    case 4: currentQuarter = [new Date(verifDate.getFullYear(), 9, 1), new Date(verifDate.getFullYear(), 11, 31),]
      break;
    default: currentQuarter = []
      break;
}*/

  //const startDateNotification = new Date(currentQuarter[0])
  //startDateNotification.setMonth(currentQuarter[0].getMonth() - 1)        // вычитаем 1 месяц от начала квартала      

  // console.log("Дата прошлой поверки", new Date(verifDateFromDB))
  // console.log("Дата прошлой поверки + 4 года", verifDate)
  // console.log("Квартал будущей поверки: ", verifQuarter)
  // console.log("Диапазон дат квартала", currentQuarter)
  // console.log("Дата начала отображения уведомления warningVerif: ", startDateNotification)
  // console.log("Дата окончания отображения уведомления warningVerif: ", currentQuarter[1])
  // console.log("Дата начала отображения уведомления warningNorma + (warningVerif + 1 секунда)): ", currentQuarter[1], "+1 сек")


 // if (today >= startDateNotification && today <= currentQuarter[1]) {    // если сегодняшняя дата позднее чем (нало квартала - 1 месяц) и раньше чем конец квартала
    // console.log("return", "warningVerif")
  //  return "warningVerif"                        // то возвращаем "warningVerif"
  }

  //if (today > currentQuarter[1]) {              // если сегодняшняя дата позднее чем конец квартала,
    // console.log("return", "warningNorma")
   // return "warningNorma"                       // то возвращаем "warningNorma"
  //}

  // console.log("return", "null")
 // return "null"                               // если сегодняшняя дата меньше даты начала отображения уведомления, - то возвращаем "null" 
//}