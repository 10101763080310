import React, { useState } from 'react'
import OneYearIndicArchive from './OneYearIndicArchive/OneYearIndicArchive'
import s from '../../PaymentArchive/OneService/OneService.module.scss'

const OneLocation = ({ oneLocation, years }) => {

  const [selectYear, setYear] = useState(years[0])
  const clickYearHandler = (year) => { setYear(year) }


  //Выбираем из архива записи выбранного пользователем года
  const oneYear = oneLocation.filter((elem) => {
    return elem.date_editing.substr(0, 4) === selectYear
  })

  // Сортируем месяцы в архиве по возрастанию (убыванию)
  oneYear.sort((a, b) => {
    // return b.date_editing.substr(5, 2) - a.date_editing.substr(5, 2)
    return a.date_editing.substr(5, 2) - b.date_editing.substr(5, 2)
  })

  // Выводим список годов
  const yearList = years.map((year) => {
    return (
      <div key={year}
        className={year === selectYear ? s.activeYear : s.passiveYear}
        onClick={clickYearHandler.bind(null, year)}
      >
        {year}
      </div>
    )
  })

  //Выводим архив выбранного года.
  return <>
    <div className={s.title2}>
      <div className={s.serviceName}>{oneLocation[0].location}</div>
      <div className={s.yearList}>{yearList}</div>
    </div>
    <OneYearIndicArchive oneYear={oneYear} />
  </>
}

export default OneLocation
