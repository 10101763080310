import React from 'react'
import s from './Aside.module.scss'

const Aside = ({ notices }) => {

  const showNotices = notices.map((not) => {
    return (<div className={s.notice} key={not.id || not.title}>
      <div className={not.type_message === 'message' ? s.messageStyle : s.warningStyle}>
        <div className={s.titleNotice}>{not.title}</div>
        <div className={s.textNotice}>{not.message} {not.phone_verify}</div>
      </div>
    </div>)
  })

  return <div className={s.aside}>

    <div className={s.aside_title}>
      <div>Інформація</div>
    </div>

    <div className={s.aside_data}>
      {showNotices}
    </div>

  </div>
}

export default Aside
